'use client'

import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, Plane, Users, Briefcase, Search, MapPin, Info, Plus, Pencil, Trash, AlertTriangle, ArrowDownUp, XCircle } from 'lucide-react';
import { collection, query, getDocs, where, Timestamp, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { Calendar } from "../components/ui/calendar";
import { Progress } from "../components/ui/progress";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";

const FlightCard = ({ flight, onEdit, onDelete }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const totalCapacity = flight.capW + flight.capY;
  const totalBoarded = flight.embC + flight.embY;
  const occupancyRate = (totalBoarded / totalCapacity) * 100;

  const destinationImages = {
    EZE: "https://americasquarterly.org/wp-content/uploads/2016/10/Argentina_Top.jpg",
    LAX: "https://www.civitatis.com/f/estados-unidos/los-angeles/los-angeles-m.jpg",
    SFO: "https://wallpapers.com/images/featured/horizonte-de-san-francisco-1ihpn12wdtk5xa9h.jpg",
    SCL: "https://www.blogdelfotografo.com/wp-content/uploads/2023/01/vista-panoramica-santiago-chile.webp",
    JFK: "https://www.vacacionesnuevayork.com/wp-content/uploads/2019/02/new-york.jpg",
    BOS: "https://content.r9cdn.net/rimg/dimg/8d/d4/5837febe-city-25588-16b90080f1e.jpg?width=1366&height=768&xhint=2723&yhint=1685&crop=true",
    BCN: "https://www.civitatis.com/blog/wp-content/uploads/2024/02/shutterstock_1891578742-1920x867.jpg",
    MIA: "https://www.viajesyfotografia.com/wp-content/uploads/2014/09/art-deco-district-miami-beach.jpg"
  };

  const destinationImage = destinationImages[flight.destination] || `/placeholder.svg?height=120&width=260`;

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
      className="boarding-pass bg-white rounded-xl shadow-lg overflow-hidden w-[260px] h-[420px] text-gray-800 uppercase relative"
      style={{
        perspective: '1000px',
        transformStyle: 'preserve-3d'
      }}
    >
      <motion.div
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        transition={{ duration: 0.6 }}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          transformStyle: 'preserve-3d'
        }}
      >
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backfaceVisibility: 'hidden'
        }}>
          <header className="bg-gradient-to-b from-gray-700 to-gray-800 p-2 h-[48px] flex justify-between items-center">
            <div className="relative w-[80px] h-[24px]">
              <img
                src="/logo.png"
                alt="Level Airlines Logo"
                className="w-full h-full object-contain"
              />
            </div>
            <div className="text-right text-white">
              <small className="block text-[7px] opacity-80 mb-0.5">flight</small>
              <strong className="block text-base">{flight.flightNumber}</strong>
            </div>
          </header>

          <div className="relative h-[120px] overflow-hidden">
            <img
              src={destinationImage}
              alt={`${flight.destination} cityscape`}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <h3 className="text-white text-2xl font-bold">{flight.destination}</h3>
            </div>
          </div>

          <section className="cities relative py-4 px-3 flex justify-between">
            <div className="city">
              <small className="block text-[10px] text-gray-600 mb-0.5">{flight.origin}</small>
              <strong className="block text-3xl font-light">{flight.origin}</strong>
            </div>
            <motion.div
              animate={{
                y: [0, -8, 0],
                rotate: [0, 5, 0, -5, 0],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <Plane className="absolute w-[24px] h-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-500" />
            </motion.div>
            <div className="city">
              <small className="block text-[10px] text-gray-600 mb-0.5">{flight.destination}</small>
              <strong className="block text-3xl font-light">{flight.destination}</strong>
            </div>
          </section>

          <section className="passenger-info border-t border-gray-200 p-4">
            <div className="grid grid-cols-2 gap-2">
              <div>
                <small className="block text-[9px] text-gray-600">CAP W</small>
                <strong className="block text-[14px]">{flight.capW}</strong>
              </div>
              <div>
                <small className="block text-[9px] text-gray-600">EMB C</small>
                <strong className="block text-[14px]">{flight.embC}</strong>
              </div>
              <div>
                <small className="block text-[9px] text-gray-600">CAP Y</small>
                <strong className="block text-[14px]">{flight.capY}</strong>
              </div>
              <div>
                <small className="block text-[9px] text-gray-600">EMB Y</small>
                <strong className="block text-[14px]">{flight.embY}</strong>
              </div>
            </div>
          </section>

          <section className="additional-info border-t border-gray-200 p-4">
            <div className="grid grid-cols-3 gap-2">
              <div>
                <small className="block text-[9px] text-gray-600">DNG</small>
                <strong className="block text-[14px]">{flight.dng || 0}</strong>
              </div>
              <div>
                <small className="block text-[9px] text-gray-600">OVBK</small>
                <strong className="block text-[14px]">{flight.ovbk || 0}</strong>
              </div>
              <div>
                <small className="block text-[9px] text-gray-600">MSS CX</small>
                <strong className="block text-[14px]">{flight.mssCx || 0}</strong>
              </div>
            </div>
          </section>

          <section className="date border-t border-gray-200 p-4">
            <div className="box">
              <small className="block text-[9px] text-gray-600">Date</small>
              <strong className="block text-[12px]">{format(flight.date, 'dd MMM yyyy')}</strong>
            </div>
          </section>

          <div className="absolute bottom-2 right-2 flex space-x-1">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsFlipped(!isFlipped)}
              className="p-1 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200"
            >
              <Info className="h-3 w-3 text-gray-600" />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => onEdit(flight)}
              className="p-1 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200"
            >
              <Pencil className="h-3 w-3 text-gray-600" />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => onDelete(flight.id)}
              className="p-1 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200"
            >
              <Trash className="h-3 w-3 text-gray-600" />
            </motion.button>
          </div>
        </div>

        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backfaceVisibility: 'hidden',
          transform: 'rotateY(180deg)',
          backgroundColor: '#ffffff',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <h3 className="text-lg font-bold mb-4 text-gray-800">Passenger Details</h3>
          <div className="mb-4">
            <p className="text-sm font-semibold text-gray-600">Business Class: {flight.embC}/{flight.capW}</p>
            <Progress value={(flight.embC / flight.capW) * 100} className="h-2 mt-1" />
          </div>
          <div className="mb-4">
            <p className="text-sm font-semibold text-gray-600">Economy Class: {flight.embY}/{flight.capY}</p>
            <Progress value={(flight.embY / flight.capY) * 100} className="h-2 mt-1" />
          </div>
          <div className="mb-4">
            <p className="text-sm font-semibold text-gray-600">Total Occupancy: {totalBoarded}/{totalCapacity}</p>
            <Progress value={occupancyRate} className="h-2 mt-1" />
          </div>
          <div className="mb-4">
            <p className="text-sm font-semibold text-gray-600">Downgrade: {flight.dng || 0}</p>
            <p className="text-sm font-semibold text-gray-600">Overbooking: {flight.ovbk || 0}</p>
            <p className="text-sm font-semibold text-gray-600">Missed Connections: {flight.mssCx || 0}</p>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsFlipped(!isFlipped)}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
          >
            Back to Flight Info
          </motion.button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default function PassengersScreen() {
  const [date, setDate] = useState(new Date());
  const [flights, setFlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [formData, setFormData] = useState({
    flightNumber: '',
    origin: '',
    destination: '',
    date: new Date(),
    capW: 0,
    capY: 0,
    embC: 0,
    embY: 0,
    dng: 0,
    ovbk: 0,
    mssCx: 0
  });

  useEffect(() => {
    fetchFlights();
  }, [date]);

  const fetchFlights = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const flightsCollection = collection(db, 'flights');
      const dayStart = new Date(date);
      dayStart.setHours(0, 0, 0, 0);
      const dayEnd = new Date(date);
      dayEnd.setHours(23, 59, 59, 999);
      const flightsQuery = query(
        flightsCollection,
        where('date', '>=', Timestamp.fromDate(dayStart)),
        where('date', '<=', Timestamp.fromDate(dayEnd))
      );
      const querySnapshot = await getDocs(flightsQuery);
      const fetchedFlights = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate(),
      }));
      setFlights(fetchedFlights);
    } catch (err) {
      console.error('Error fetching flight data:', err);
      setError('Error fetching flight data: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'number' ? parseInt(value, 10) : value
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData(prevData => ({ ...prevData, date: newDate }));
  };

  const handleAddFlight = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'flights'), formData);
      setFormData({
        flightNumber: '',
        origin: '',
        destination: '',
        date: new Date(),
        capW: 0,
        capY: 0,
        embC: 0,
        embY: 0,
        dng: 0,
        ovbk: 0,
        mssCx: 0
      });
      setIsAdding(false);
      fetchFlights();
    } catch (error) {
      console.error("Error adding flight: ", error);
      setError("Error adding flight: " + error.message);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (selectedFlight) {
      try {
        const flightRef = doc(db, 'flights', selectedFlight.id);
        await updateDoc(flightRef, formData);
        setSelectedFlight(null);
        fetchFlights();
      } catch (error) {
        console.error("Error updating flight: ", error);
        setError("Error updating flight: " + error.message);
      }
    }
  };

  const handleDeleteFlight = async (flightId) => {
    if (window.confirm('Are you sure you want to delete this flight?')) {
      try {
        await deleteDoc(doc(db, 'flights', flightId));
        fetchFlights();
      } catch (error) {
        console.error("Error deleting flight: ", error);
        setError("Error deleting flight: " + error.message);
      }
    }
  };

  const filteredFlights = useMemo(() => {
    return flights.filter(flight => {
      return flight.flightNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flight.origin.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flight.destination.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [flights, searchTerm]);

  const sortedFlights = useMemo(() => {
    let sortableFlights = [...filteredFlights];
    if (sortConfig.key !== null) {
      sortableFlights.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableFlights;
  }, [filteredFlights, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const totals = useMemo(() => {
    return flights.reduce((acc, flight) => {
      acc.totalCapacity += flight.capW + flight.capY;
      acc.totalBoarded += flight.embC + flight.embY;
      acc.businessCapacity += flight.capW;
      acc.businessBoarded += flight.embC;
      acc.economyCapacity += flight.capY;
      acc.economyBoarded += flight.embY;
      acc.totalDowngrades += flight.dng || 0;
      acc.totalOverbookings += flight.ovbk || 0;
      acc.totalMissedConnections += flight.mssCx || 0;
      return acc;
    }, { totalCapacity: 0, totalBoarded: 0, businessCapacity: 0, businessBoarded: 0, economyCapacity: 0, economyBoarded: 0, totalDowngrades: 0, totalOverbookings: 0, totalMissedConnections: 0 });
  }, [flights]);

  const renderForm = () => (
    <motion.form
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      onSubmit={selectedFlight ? handleEditSubmit : handleAddFlight}
      className="space-y-4 bg-white p-6 rounded-lg shadow-xl"
    >
      <Input
        type="text"
        name="flightNumber"
        value={formData.flightNumber}
        onChange={handleInputChange}
        placeholder="Flight Number"
        className="w-full"
        required
      />
      <div className="flex space-x-4">
        <Input
          type="text"
          name="origin"
          value={formData.origin}
          onChange={handleInputChange}
          placeholder="Origin"
          className="w-1/2"
          required
        />
        <Input
          type="text"
          name="destination"
          value={formData.destination}
          onChange={handleInputChange}
          placeholder="Destination"
          className="w-1/2"
          required
        />
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={`w-full justify-start text-left font-normal ${!formData.date && "text-muted-foreground"}`}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {formData.date ? format(formData.date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={formData.date}
            onSelect={handleDateChange}
            initialFocus
            className="bg-white"
          />
        </PopoverContent>
      </Popover>
      <div className="grid grid-cols-2 gap-4">
        <Input
          type="number"
          name="capW"
          value={formData.capW}
          onChange={handleInputChange}
          placeholder="CAP W"
          className="w-full"
          required
        />
        <Input
          type="number"
          name="embC"
          value={formData.embC}
          onChange={handleInputChange}
          placeholder="EMB C"
          className="w-full"
          required
        />
        <Input
          type="number"
          name="capY"
          value={formData.capY}
          onChange={handleInputChange}
          placeholder="CAP Y"
          className="w-full"
          required
        />
        <Input
          type="number"
          name="embY"
          value={formData.embY}
          onChange={handleInputChange}
          placeholder="EMB Y"
          className="w-full"
          required
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <Input
          type="number"
          name="dng"
          value={formData.dng}
          onChange={handleInputChange}
          placeholder="DNG"
          className="w-full"
        />
        <Input
          type="number"
          name="ovbk"
          value={formData.ovbk}
          onChange={handleInputChange}
          placeholder="OVBK"
          className="w-full"
        />
        <Input
          type="number"
          name="mssCx"
          value={formData.mssCx}
          onChange={handleInputChange}
          placeholder="MSS CX"
          className="w-full"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            setSelectedFlight(null);
            setIsAdding(false);
            setFormData({
              flightNumber: '',
              origin: '',
              destination: '',
              date: new Date(),
              capW: 0,
              capY: 0,
              embC: 0,
              embY: 0,
              dng: 0,
              ovbk: 0,
              mssCx: 0
            });
          }}
        >
          Cancel
        </Button>
        <Button type="submit">
          {selectedFlight ? 'Update Flight' : 'Add Flight'}
        </Button>
      </div>
    </motion.form>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <motion.div
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1
          }}
          className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full"
        />
      </div>
    );
  }

  if (error) {
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex justify-center items-center h-screen bg-gray-100 text-red-500"
      >
        {error}
      </motion.div>
    );
  }

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <div className="max-w-7xl mx-auto">
         <div className="mb-8 flex flex-wrap justify-between items-center gap-4">
          <div className="flex-1 min-w-[200px]">
            <div className="relative">
              <Input
                type="text"
                placeholder="Search flights..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>
          <div className="flex-1 min-w-[200px]">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={`w-full justify-start text-left font-normal ${!date && "text-muted-foreground"}`}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={(newDate) => {
                    setDate(newDate);
                    fetchFlights();
                  }}
                  initialFocus
                  className="bg-white"
                />
              </PopoverContent>
            </Popover>
          </div>
          <Button onClick={() => setIsAdding(true)}>
            <Plus className="h-5 w-5 mr-2" />
            Add Flight
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://media.licdn.com/dms/image/D4D12AQE-NkDEv98TjQ/article-cover_image-shrink_720_1280/0/1702553880949?e=2147483647&v=beta&t=xu9tciPVGgXAwGQH0w7hsS6dwbdR7F50RXYY4xP24AQ')"}}></div>
            <div className="relative bg-gradient-to-r from-blue-600/80 to-blue-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total Passengers</CardTitle>
                <Users className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.totalCapacity}/{totals.totalBoarded}</div>
                <p className="text-xs">
                  {((totals.totalBoarded / totals.totalCapacity) * 100).toFixed(2)}% Occupancy
                </p>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://fly-news.es/wp-content/uploads/Level-Turista-Premium-1.jpg')"}}></div>
            <div className="relative bg-gradient-to-r from-purple-600/80 to-purple-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Premium (w)</CardTitle>
                <Briefcase className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.businessCapacity}/{totals.businessBoarded}</div>
                <p className="text-xs">
                  {((totals.businessBoarded / totals.businessCapacity) * 100).toFixed(2)}% Occupancy
                </p>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://cdn.guialowcost.es/wp-content/uploads/INTERIOR-CABINA-DE-LEVEL-asientos-turista.jpg')"}}></div>
            <div className="relative bg-gradient-to-r from-green-600/80 to-green-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Tourist (Y)</CardTitle>
                <Users className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.economyCapacity}/{totals.economyBoarded}</div>
                <p className="text-xs">
                  {((totals.economyBoarded / totals.economyCapacity) * 100).toFixed(2)}% Occupancy
                </p>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://s1.elespanol.com/2020/06/18/invertia/empresas/turismo/turismo-coronavirus-enfermedades_infecciosas_498711608_154014173_1706x1280.jpg')"}}></div>
            <div className="relative bg-gradient-to-r from-gray-800/80 to-gray-800/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total Flights</CardTitle>
                <Plane className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{flights.length}</div>
                <p className="text-xs">
                  For {format(date, "MMMM d, yyyy")}
                </p>
              </CardContent>
            </div>
          </Card>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1533496924042-6d2aaf404f8a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')"}}></div>
            <div className="relative bg-gradient-to-r from-red-600/80 to-red-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Downgrades</CardTitle>
                <ArrowDownUp className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.totalDowngrades}</div>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1506349911065-c36bea39351c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')"}}></div>
            <div className="relative bg-gradient-to-r from-yellow-600/80 to-yellow-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Overbookings</CardTitle>
                <AlertTriangle className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.totalOverbookings}</div>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="absolute inset-0 bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1521028640727-6ee2b89db692?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')"}}></div>
            <div className="relative bg-gradient-to-r from-orange-600/80 to-orange-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Missed Connections</CardTitle>
                <XCircle className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.totalMissedConnections}</div>
              </CardContent>
            </div>
          </Card>
        </div>

        {isAdding || selectedFlight ? renderForm() : null}

        {sortedFlights.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
          >
            {sortedFlights.map((flight) => (
              <FlightCard
                key={flight.id}
                flight={flight}
                onEdit={(flight) => {
                  setSelectedFlight(flight);
                  setFormData(flight);
                }}
                onDelete={handleDeleteFlight}
              />
            ))}
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center text-gray-800 mt-12"
          >
            <p className="text-2xl font-semibold">No flights found</p>
            <p className="mt-2">Try changing your search filters</p>
          </motion.div>
        )}
      </div>
    </div>
  );
}