import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import AdminDashboard from './components/AdminDashboard';
import FlightsScreen from './components/FlightsScreen';
import PassengersScreen from './components/PassengersScreen';
import ContactManagement from './components/ContactManagement';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-background text-foreground">
      <Router>
        <Routes>
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/admin" />} />
          <Route 
            path="/admin/*" 
            element={user ? <AdminDashboard /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/flights" 
            element={user ? <FlightsScreen /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/passengers" 
            element={user ? <PassengersScreen /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/contacts" 
            element={user ? <ContactManagement /> : <Navigate to="/login" />} 
          />
          <Route path="/" element={<Navigate to={user ? "/admin" : "/login"} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;