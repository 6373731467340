import React, { useState } from 'react'
import { cn } from "../../lib/utils"

const TooltipProvider = ({ children }) => children

const Tooltip = ({ children }) => children

const TooltipTrigger = ({ children, ...props }) => React.cloneElement(children, props)

const TooltipContent = ({ className, children }) => {
  return (
    <div
      className={cn(
        "absolute z-50 px-2 py-1 text-sm text-white bg-gray-800 rounded-md shadow-lg",
        "animate-in fade-in-0 zoom-in-95",
        className
      )}
    >
      {children}
    </div>
  )
}

const TooltipRoot = ({ children, content, className }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && <TooltipContent className={className}>{content}</TooltipContent>}
    </div>
  )
}

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipRoot }