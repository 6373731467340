import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB-uKNDLG68j7YnaWW_YGXVBt7SNeqpuvU",
  authDomain: "dashboard-4d770.firebaseapp.com",
  projectId: "dashboard-4d770",
  storageBucket: "dashboard-4d770.appspot.com",
  messagingSenderId: "588845617740",
  appId: "1:588845617740:web:YOUR_WEB_APP_ID_HERE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };