'use client'

import React, { useState, useEffect } from 'react'
import { collection, getDocs, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import { createUserWithEmailAndPassword, deleteUser, getAuth } from 'firebase/auth'
import { db } from '../firebase'

export default function UserManagement() {
  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState(['OPS', 'NCR', 'CALL CENTER', 'MANAGER', 'OCC', 'RRHH', 'CREW'])
  const [selectedUser, setSelectedUser] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [isManagingRoles, setIsManagingRoles] = useState(false)
  const [formData, setFormData] = useState({ name: '', department: '', email: '', phone: '', password: '', role: 'CREW' })
  const [searchTerm, setSearchTerm] = useState('')
  const [newRole, setNewRole] = useState('')
  const [editingRole, setEditingRole] = useState(null)
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    fetchUsers()
    fetchRoles()
  }, [])

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, 'users')
      const userSnapshot = await getDocs(usersCollection)
      const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setUsers(userList)
    } catch (error) {
      console.error("Error fetching users: ", error)
    }
  }

  const fetchRoles = async () => {
    try {
      const rolesCollection = collection(db, 'roles')
      const rolesSnapshot = await getDocs(rolesCollection)
      const rolesList = rolesSnapshot.docs.map(doc => doc.data().name)
      setRoles(rolesList)
    } catch (error) {
      console.error("Error fetching roles: ", error)
    }
  }

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleAddUser = async (e) => {
    e.preventDefault()
    try {
      const auth = getAuth()
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password)
      const user = userCredential.user

      const { password, ...userDataWithoutPassword } = formData
      await addDoc(collection(db, 'users'), {
        ...userDataWithoutPassword,
        uid: user.uid
      })

      setFormData({ name: '', department: '', email: '', phone: '', password: '', role: 'CREW' })
      setIsAdding(false)
      await fetchUsers()
    } catch (error) {
      console.error("Error adding user: ", error)
    }
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault()
    if (selectedUser) {
      try {
        const userRef = doc(db, 'users', selectedUser.id)
        const { password, ...updateData } = formData
        await updateDoc(userRef, updateData)
        setSelectedUser(null)
        await fetchUsers()
      } catch (error) {
        console.error("Error updating user: ", error)
      }
    }
  }

  const handleDeleteUser = async (userId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este usuario?')) {
      try {
        await deleteDoc(doc(db, 'users', userId))
        await fetchUsers()
      } catch (error) {
        console.error("Error deleting user: ", error)
      }
    }
  }

  const handleAddRole = async () => {
    if (newRole.trim() !== '') {
      try {
        await addDoc(collection(db, 'roles'), { name: newRole.trim() })
        setNewRole('')
        await fetchRoles()
      } catch (error) {
        console.error("Error adding role: ", error)
      }
    }
  }

  const handleEditRole = async (oldRole, newRoleName) => {
    try {
      const rolesCollection = collection(db, 'roles')
      const roleQuery = await getDocs(rolesCollection)
      const roleDoc = roleQuery.docs.find(doc => doc.data().name === oldRole)
      
      if (roleDoc) {
        await updateDoc(doc(db, 'roles', roleDoc.id), { name: newRoleName })
        await fetchRoles()
        setEditingRole(null)
      }
    } catch (error) {
      console.error("Error editing role: ", error)
    }
  }

  const handleDeleteRole = async (roleName) => {
    if (window.confirm(`¿Estás seguro de que quieres eliminar el rol "${roleName}"?`)) {
      try {
        const rolesCollection = collection(db, 'roles')
        const roleQuery = await getDocs(rolesCollection)
        const roleDoc = roleQuery.docs.find(doc => doc.data().name === roleName)
        
        if (roleDoc) {
          await deleteDoc(doc(db, 'roles', roleDoc.id))
          await fetchRoles()
        }
      } catch (error) {
        console.error("Error deleting role: ", error)
      }
    }
  }

  const filteredUsers = users.filter(user =>
    Object.values(user).some(value => 
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const getRoleColor = (role) => {
    const colors = {
      'OPS': '#3b82f6',
      'NCR': '#10b981',
      'CALL CENTER': '#f59e0b',
      'MANAGER': '#8b5cf6',
      'OCC': '#ef4444',
      'RRHH': '#ec4899',
      'CREW': '#6366f1'
    }
    return colors[role] || '#6b7280'
  }

  const renderForm = () => (
    <form onSubmit={selectedUser ? handleEditSubmit : handleAddUser} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="name" className="block mb-1 text-sm font-medium">Nombre</label>
          <input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div>
          <label htmlFor="department" className="block mb-1 text-sm font-medium">Departamento</label>
          <input
            id="department"
            name="department"
            value={formData.department}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>
      <div>
        <label htmlFor="email" className="block mb-1 text-sm font-medium">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          required
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
      <div>
        <label htmlFor="phone" className="block mb-1 text-sm font-medium">Teléfono</label>
        <input
          id="phone"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleInputChange}
          required
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
      {!selectedUser && (
        <div>
          <label htmlFor="password" className="block mb-1 text-sm font-medium">Contraseña</label>
          <input
            id="password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      )}
      <div>
        <label htmlFor="role" className="block mb-1 text-sm font-medium">Rol</label>
        <select
          id="role"
          name="role"
          value={formData.role}
          onChange={handleInputChange}
          required
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          {roles.map((role) => (
            <option key={role} value={role}>{role}</option>
          ))}
        </select>
      </div>
      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={() => {
            setSelectedUser(null)
            setIsAdding(false)
            setFormData({ name: '', department: '', email: '', phone: '', password: '', role: 'CREW' })
          }}
          className="px-4 py-2 border rounded-md hover:bg-gray-100 transition-colors duration-200"
        >
          Cancelar
        </button>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200">
          {selectedUser ? 'Actualizar Usuario' : 'Añadir Usuario'}
        </button>
      </div>
    </form>
  )

  const renderRoleManagement = () => (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold mb-4">Gestionar Roles</h3>
      <div className="flex space-x-2">
        <input
          value={newRole}
          onChange={(e) => setNewRole(e.target.value)}
          placeholder="Nuevo rol"
          className="flex-grow p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <button onClick={handleAddRole} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200">
          Añadir Rol
        </button>
      </div>
      <div className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md">
        <ul className="space-y-2">
          {roles.map((role) => (
            <li key={role} className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700 rounded-md">
              {editingRole === role ? (
                <input
                  value={editingRole}
                  onChange={(e) => setEditingRole(e.target.value)}
                  className="flex-grow p-1 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              ) : (
                <span>{role}</span>
              )}
              <div className="space-x-2">
                {editingRole === role ? (
                  <>
                    <button onClick={() => handleEditRole(role, editingRole)} className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 text-sm">
                      Guardar
                    </button>
                    <button onClick={() => setEditingRole(null)} className="px-2 py-1 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors duration-200 text-sm">
                      Cancelar
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => setEditingRole(role)} className="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 text-sm">
                      Editar
                    </button>
                    <button onClick={() => handleDeleteRole(role)} className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 text-sm">
                      Eliminar
                    </button>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

  return (
    <div className={`min-h-screen p-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-blue-100 to-purple-100 text-gray-900'}`}>
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold">Sistema de Gestión de Usuarios</h1>
          <button
            onClick={() => setIsDarkMode(!isDarkMode)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 transition-colors duration-200"
          >
            {isDarkMode ? 'Modo Claro' : 'Modo Oscuro'}
          </button>
        </div>

        <div className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white bg-opacity-70 backdrop-blur-md'}`}>
          <h2 className="text-2xl font-bold mb-4">Acciones</h2>
          <div className="flex flex-wrap justify-between items-center gap-4">
            <div className="flex-1 min-w-[200px]">
              <input
                type="text"
                placeholder="Buscar usuarios..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setIsAdding(!isAdding)
                  setIsManagingRoles(false)
                  setSelectedUser(null)
                  setFormData({ name: '', department: '', email: '', phone: '', password: '', role: 'CREW' })
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200"
              >
                {isAdding ? 'Cancelar' : 'Añadir Usuario'}
              </button>
              <button
                onClick={() => {
                  setIsManagingRoles(!isManagingRoles)
                  setIsAdding(false)
                  setSelectedUser(null)
                }}
                className="px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200"
              >
                {isManagingRoles ? 'Cancelar' : 'Gestionar Roles'}
              </button>
            </div>
          </div>
        </div>

        {(isAdding || selectedUser || isManagingRoles) && (
          <div className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white bg-opacity-70 backdrop-blur-md'}`}>
            {isManagingRoles ? (
              renderRoleManagement()
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-4">
                  {selectedUser ? 'Editar Usuario' : 'Añadir Nuevo Usuario'}
                </h2>
                {renderForm()}
              </>
            )}
          </div>
        )}

        <div className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white bg-opacity-70 backdrop-blur-md'}`}>
          <h2 className="text-2xl font-bold mb-4">Lista de Usuarios</h2>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
                <tr>
                  <th className="p-2 text-left">Nombre</th>
                  <th className="p-2 text-left">Departamento</th>
                  <th className="p-2 text-left">Email</th>
                  <th className="p-2 text-left">Teléfono</th>
                  <th className="p-2 text-left">Rol</th>
                  <th className="p-2 text-right">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id} className={`border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                    <td className="p-2">{user.name}</td>
                    <td className="p-2">{user.department}</td>
                    <td className="p-2">{user.email}</td>
                    <td className="p-2">{user.phone}</td>
                    <td className="p-2">
                      <span
                        className="px-2 py-1 rounded-full text-white text-sm"
                        style={{ backgroundColor: getRoleColor(user.role) }}
                      >
                        {user.role}
                      </span>
                    </td>
                    <td className="p-2 text-right">
                      <button
                        onClick={() => {
                          setSelectedUser(user)
                          setFormData({ ...user, password: '' })
                          setIsAdding(false)
                          setIsManagingRoles(false)
                        }}
                        className="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 text-sm mr-2"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-200 text-sm"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}