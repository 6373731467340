'use client'

import React, { useState, useCallback } from 'react';
import { Upload, CheckCircle, AlertCircle } from 'lucide-react';
import * as XLSX from 'xlsx';
import { collection, writeBatch, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { parse, isValid, isAfter, startOfYear } from 'date-fns';

export default function DataUploadScreen() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [logs, setLogs] = useState([]);

  const addLog = useCallback((message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    console.log(message); // Para debugging
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError(null);
    setSuccess(false);
    setProgress(0);
    setLogs([]);
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Por favor, selecciona un archivo para subir.');
      return;
    }

    setUploading(true);
    setError(null);
    setSuccess(false);
    setProgress(0);
    setLogs([]);

    try {
      const data = await readExcel(file);
      await uploadDataToFirestore(data);
      setSuccess(true);
      setFile(null);
    } catch (err) {
      console.error('Error durante la subida:', err);
      setError('Error al subir el archivo: ' + err.message);
    } finally {
      setUploading(false);
    }
  };

  const readExcel = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          let allData = [];
          workbook.SheetNames.forEach((sheetName) => {
            if (sheetName === 'DELAY_CODES') {
              addLog('Omitiendo la hoja DELAY_CODES');
              return;
            }

            const worksheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(worksheet, { raw: false, header: 1 });
            
            const yearMatch = sheetName.match(/\d{4}/);
            if (yearMatch) {
              const year = parseInt(yearMatch[0], 10);
              if (year >= 2024) {
                const processedData = sheetData.slice(2).map(row => ({
                  date: row[3] || '',
                  flightNumber: row[1] || '',
                  registration: row[2] || '',
                  origin: row[4] || '',
                  destination: row[11] || '',
                  std: row[7] || '',
                  realTimeDeparture: row[8] || '',
                  sta: row[12] || '',
                  realTimeArrival: row[13] || '',
                  capW: row[36] || '0',
                  capY: row[37] || '0',
                  expC: row[20] || '0',
                  expY: row[21] || '0',
                  embC: row[22] || '0',
                  embY: row[23] || '0',
                  delayCode1: row[27] || '',
                  delayComment1: row[29] || '',
                  delayCode2: row[30] || '',
                  delayComment2: row[32] || '',
                  delayCode3: row[33] || '',
                  delayComment3: row[35] || '',
                  isCancelled: (row[5] || '').toLowerCase() === 'cancelled',
                  year: year
                }));
                allData = allData.concat(processedData);
                addLog(`Procesando hoja: ${sheetName} (Año: ${year})`);
              } else {
                addLog(`Saltando hoja ${sheetName} por ser anterior a 2024`);
              }
            } else {
              addLog(`No se pudo extraer el año de la hoja: ${sheetName}. Se omitirá esta hoja.`);
            }
          });
          resolve(allData);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadDataToFirestore = async (data) => {
    const flightsCollection = collection(db, 'flights');
    let uploadedCount = 0;
    let skippedCount = 0;
    const batchSize = 500;
    const totalRows = data.length;
    const year2024Start = startOfYear(new Date(2024, 0, 1));

    for (let i = 0; i < totalRows; i += batchSize) {
      const batch = writeBatch(db);
      const endIndex = Math.min(i + batchSize, totalRows);

      for (let j = i; j < endIndex; j++) {
        const row = data[j];
        try {
          if (!row.flightNumber || !row.date) {
            addLog(`Fila inválida, falta número de vuelo o fecha: ${JSON.stringify(row)}`);
            skippedCount++;
            continue;
          }

          const convertedDate = parse(row.date, 'M/d/yy', new Date(row.year, 0, 1));
          if (!isValid(convertedDate) || !isAfter(convertedDate, year2024Start)) {
            addLog(`Fecha inválida o anterior a 2024 para el vuelo: ${row.flightNumber}, fecha: ${row.date}`);
            skippedCount++;
            continue;
          }

          const formatTime = (time) => {
            if (!time) return null;
            const [hours, minutes] = time.split(':').map(Number);
            return new Date(0, 0, 0, hours, minutes).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', hour12: false });
          };

          const flightData = {
            flightNumber: row.flightNumber,
            registration: row.registration,
            origin: row.origin,
            destination: row.destination,
            date: Timestamp.fromDate(convertedDate),
            std: formatTime(row.std),
            realTimeDeparture: formatTime(row.realTimeDeparture),
            sta: formatTime(row.sta),
            realTimeArrival: formatTime(row.realTimeArrival),
            capW: parseInt(row.capW) || 0,
            capY: parseInt(row.capY) || 0,
            expC: parseInt(row.expC) || 0,
            expY: parseInt(row.expY) || 0,
            embC: parseInt(row.embC) || 0,
            embY: parseInt(row.embY) || 0,
            delayCode1: row.delayCode1,
            delayComment1: row.delayComment1,
            delayCode2: row.delayCode2,
            delayComment2: row.delayComment2,
            delayCode3: row.delayCode3,
            delayComment3: row.delayComment3,
            isCancelled: row.isCancelled,
            year: row.year
          };

          // Verificar que todos los campos requeridos estén presentes
          const requiredFields = ['flightNumber', 'registration', 'origin', 'destination', 'date', 'std', 'sta'];
          const missingFields = requiredFields.filter(field => !flightData[field]);
          
          if (missingFields.length > 0) {
            addLog(`Fila saltada, faltan campos requeridos: ${missingFields.join(', ')} para el vuelo ${row.flightNumber}`);
            skippedCount++;
            continue;
          }

          const docId = `${flightData.flightNumber}_${flightData.date.toDate().toISOString().split('T')[0]}`;
          const docRef = doc(flightsCollection, docId);
          batch.set(docRef, flightData);
          uploadedCount++;
        } catch (err) {
          console.error('Error al preparar la fila para subir:', err, row);
          addLog(`Error al preparar la fila: ${err.message}`);
          skippedCount++;
        }
      }

      try {
        await batch.commit();
        addLog(`Lote subido. Total subidos: ${uploadedCount}`);
        setProgress(Math.round((endIndex / totalRows) * 100));
      } catch (err) {
        console.error('Error al subir el lote:', err);
        addLog(`Error al subir el lote: ${err.message}`);
        throw err;
      }
    }

    addLog(`Subida completa. Subidos: ${uploadedCount}, Saltados: ${skippedCount}`);
    if (uploadedCount === 0) {
      throw new Error('No se subieron vuelos. Verifica que el archivo contenga vuelos de 2024 en adelante.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-center">Subir Datos Excel (Solo vuelos de 2024 en adelante)</h1>
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="file-upload">
          Archivo Excel
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      <button
        onClick={handleUpload}
        disabled={!file || uploading}
        className={`w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
          (!file || uploading) && 'opacity-50 cursor-not-allowed'
        }`}
      >
        {uploading ? (
          <>
            <Upload className="animate-spin -ml-1 mr-3 h-5 w-5" />
            Subiendo...
          </>
        ) : (
          <>
            <Upload className="-ml-1 mr-3 h-5 w-5" />
            Subir
          </>
        )}
      </button>
      {uploading && (
        <div className="mt-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-indigo-600 h-2.5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-600 mt-2">Progreso: {progress}%</p>
        </div>
      )}
      {error && (
        <div className="mt-4 flex items-center text-red-600">
          <AlertCircle className="mr-2" />
          <p className="text-sm">{error}</p>
        </div>
      )}
      {success && (
        <div className="mt-4 flex items-center text-green-600">
          <CheckCircle className="mr-2" />
          <p className="text-sm">¡Archivo subido con éxito!</p>
        </div>
      )}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Registros de subida</h2>
        <div className="h-64 w-full rounded-md border p-4 overflow-y-auto">
          {logs.map((log, index) => (
            <p key={index} className="text-sm text-gray-700">{log}</p>
          ))}
        </div>
      </div>
    </div>
  );
}