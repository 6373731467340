'use client'

import React, { useState, useEffect, useCallback } from 'react'
import { UserCircle, CalendarDays, CheckCircle, Edit, Trash, Clock, X, Search, Bell, Settings, ChevronLeft, ChevronRight, Plus, PlusCircle, Calendar, ChevronDown, ChevronUp, ListPlus } from 'lucide-react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc, getDocs, updateDoc, doc, deleteDoc, query, where, Timestamp, orderBy, limit, onSnapshot } from 'firebase/firestore'
import { motion, AnimatePresence } from 'framer-motion'
import { Line, Pie } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement)

const firebaseConfig = {
  apiKey: "AIzaSyB-uKNDLG68j7YnaWW_YGXVBt7SNeqpuvU",
  authDomain: "dashboard-4d770.firebaseapp.com",
  projectId: "dashboard-4d770",
  storageBucket: "dashboard-4d770.appspot.com",
  messagingSenderId: "588845617740",
  appId: "1:588845617740:web:YOUR_WEB_APP_ID_HERE"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const predefinedQueries = [
  "Necesita reubicación",
  "Consulta Reubicación",
  "Devolver a estado Inicial",
  "Estado HP/UN/UC",
  "Reubicación VY"
]

const CollapsibleText = ({ text, maxLength = 50 }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const displayText = isExpanded ? text : text.slice(0, maxLength)

  return (
    <div className="relative">
      <p className="text-xs whitespace-normal break-words">
        {displayText}
        {text.length > maxLength && !isExpanded && '...'}
      </p>
      {text.length > maxLength && (
        <button
          onClick={toggleExpand}
          className="text-blue-500 hover:text-blue-700 text-xs mt-1 flex items-center"
        >
          {isExpanded ? (
            <>
              <ChevronUp size={14} className="mr-1" />
              Ver menos
            </>
          ) : (
            <>
              <ChevronDown size={14} className="mr-1" />
              Ver más
            </>
          )}
        </button>
      )}
    </div>
  )
}

export default function CallCenter() {
  const [tickets, setTickets] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingTicket, setEditingTicket] = useState(null)
  const [formData, setFormData] = useState({
    caseNumber: '',
    ticketNumber: '',
    pnr: '',
    query: '',
    response: '',
    origin: '',
    destination: ''
  })
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [searchTerm, setSearchTerm] = useState('')
  const [notificationCount, setNotificationCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [queries, setQueries] = useState([])
  const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState(false)
  const [isEditQueryModalOpen, setIsEditQueryModalOpen] = useState(false)
  const [isDeleteQueryModalOpen, setIsDeleteQueryModalOpen] = useState(false)
  const [newQuery, setNewQuery] = useState('')
  const [editingQuery, setEditingQuery] = useState(null)
  const [deletingQuery, setDeletingQuery] = useState(null)
  const [currentUser] = useState({ name: 'John Doe', image: `https://randomuser.me/api/portraits/${Math.random() < 0.5 ? 'men' : 'women'}/${Math.floor(Math.random() * 100)}.jpg` })
  const [expandedTicket, setExpandedTicket] = useState(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isQueryManagementOpen, setIsQueryManagementOpen] = useState(false)
  const [lastCaseNumber, setLastCaseNumber] = useState(0)

  const fetchTickets = useCallback(() => {
    setIsLoading(true)
    setError(null)
    try {
      const startOfDay = new Date(selectedDate)
      startOfDay.setHours(0, 0, 0, 0)
      const endOfDay = new Date(selectedDate)
      endOfDay.setHours(23, 59, 59, 999)

      const q = query(
        collection(db, "callcenter"),
        where("timestamp", ">=", Timestamp.fromDate(startOfDay)),
        where("timestamp", "<=", Timestamp.fromDate(endOfDay)),
        orderBy("timestamp", "desc")
      )

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedTickets = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        setTickets(fetchedTickets)
        setNotificationCount(fetchedTickets.filter(ticket => !ticket.solved).length)
        
        // Update lastCaseNumber
        const maxCaseNumber = fetchedTickets.reduce((max, ticket) => {
          if (ticket.caseNumber && typeof ticket.caseNumber === 'string') {
            const caseNum = parseInt(ticket.caseNumber.slice(2), 10)
            return isNaN(caseNum) ? max : Math.max(max, caseNum)
          }
          return max
        }, 0)
        setLastCaseNumber(maxCaseNumber)
        
        setIsLoading(false)
      }, (error) => {
        console.error("Error fetching tickets:", error)
        setError("Failed to fetch tickets. Please try again.")
        setIsLoading(false)
      })

      return unsubscribe
    } catch (error) {
      console.error("Error setting up ticket listener:", error)
      setError("Failed to set up ticket listener. Please try again.")
      setIsLoading(false)
    }
  }, [selectedDate])

  useEffect(() => {
    const unsubscribe = fetchTickets()
    return () => unsubscribe && unsubscribe()
  }, [fetchTickets])

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "queries"))
        const fetchedQueries = querySnapshot.docs.map(doc => ({ id: doc.id, text: doc.data().text }))
        setQueries([...predefinedQueries.map(q => ({ id: 'predefined', text: q })), ...fetchedQueries])
      } catch (error) {
        console.error("Error fetching queries:", error)
        setError("Failed to fetch queries. Please try again.")
      }
    }
    fetchQueries()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const newTicket = {
        ...formData,
        solved: false,
        timestamp: Timestamp.fromDate(new Date()),
        createdBy: currentUser.name,
      }
      if (editingTicket) {
        await updateDoc(doc(db, "callcenter", editingTicket.id), newTicket)
      } else {
        // Generate a new unique case number
        const newCaseNumber = lastCaseNumber + 1
        const caseNumberString = `LV${newCaseNumber.toString().padStart(4, '0')}`
        newTicket.caseNumber = caseNumberString
        await addDoc(collection(db, "callcenter"), newTicket)
        setLastCaseNumber(newCaseNumber)
      }
      setIsModalOpen(false)
      setEditingTicket(null)
      setFormData({
        caseNumber: '',
        ticketNumber: '',
        pnr: '',
        query: '',
        response: '',
        origin: '',
        destination: ''
      })
    } catch (error) {
      console.error("Error submitting ticket:", error)
      setError("Failed to submit ticket. Please try again.")
    }
  }

  const handleEdit = (ticket) => {
    setEditingTicket(ticket)
    setFormData({
      caseNumber: ticket.caseNumber,
      ticketNumber: ticket.ticketNumber,
      pnr: ticket.pnr,
      query: ticket.query,
      response: ticket.response,
      origin: ticket.origin || '',
      destination: ticket.destination || ''
    })
    setIsModalOpen(true)
  }

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "callcenter", id))
      if (selectedTicket && selectedTicket.id === id) {
        setSelectedTicket(null)
      }
    } catch (error) {
      console.error("Error deleting ticket:", error)
      setError("Failed to delete ticket. Please try again.")
    }
  }

  const handleToggleSolve = async (id, currentSolvedStatus) => {
    try {
      await updateDoc(doc(db, "callcenter", id), {
        solved: !currentSolvedStatus
      })
      if (selectedTicket && selectedTicket.id === id) {
        setSelectedTicket(prev => ({ ...prev, solved: !currentSolvedStatus }))
      }
    } catch (error) {
      console.error("Error updating ticket status:", error)
      setError("Failed to update ticket status. Please try again.")
    }
  }

  const handleAddQuery = async () => {
    if (newQuery.trim() !== '') {
      try {
        const docRef = await addDoc(collection(db, "queries"), { text: newQuery })
        setQueries(prev => [...prev, { id: docRef.id, text: newQuery }])
        setNewQuery('')
        setIsAddQueryModalOpen(false)
      } catch (error) {
        console.error("Error adding query:", error)
        setError("Failed to add new query. Please try again.")
      }
    }
  }

  const handleEditQuery = async () => {
    if (editingQuery && editingQuery.text.trim() !== '') {
      try {
        await updateDoc(doc(db, "queries", editingQuery.id), { text: editingQuery.text })
        setQueries(prev => prev.map(q => q.id === editingQuery.id ? editingQuery : q))
        setIsEditQueryModalOpen(false)
        setEditingQuery(null)
      } catch (error) {
        console.error("Error editing query:", error)
        setError("Failed to edit query. Please try again.")
      }
    }
  }

  const handleDeleteQuery = async () => {
    if (deletingQuery) {
      try {
        await deleteDoc(doc(db, "queries", deletingQuery.id))
        setQueries(prev => prev.filter(q => q.id !== deletingQuery.id))
        setIsDeleteQueryModalOpen(false)
        setDeletingQuery(null)
      } catch (error) {
        console.error("Error deleting query:", error)
        setError("Failed to delete query. Please try again.")
      }
    }
  }

  const changeDate = (increment) => {
    const newDate = new Date(selectedDate)
    newDate.setDate(newDate.getDate() + increment)
    setSelectedDate(newDate)
  }

  const filteredTickets = tickets.filter(ticket =>
    (ticket.caseNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (ticket.ticketNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (ticket.pnr?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (ticket.query?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (ticket.origin?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (ticket.destination?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
  )

  const toggleExpandTicket = (ticketId) => {
    setExpandedTicket(expandedTicket === ticketId ? null : ticketId)
  }

  if (isLoading) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-sm font-semibold text-gray-600 animate-pulse">Loading...</div>
    </div>
  }

  if (error) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-sm font-medium text-red-600">{error}</div>
    </div>
  }

  return (
    <div className="min-h-screen bg-gray-0 p-4 font-sans text-xs">
      <div className="max-w-7xl mx-auto">
        <header className="bg-cover bg-center shadow-md rounded-lg p-4 mb-6 relative overflow-hidden" style={{backgroundImage: `url(https://images4.alphacoders.com/129/1299844.jpg)`}}>
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="flex justify-between items-center relative z-10">
            <div className="flex items-center space-x-4">
              <img src={currentUser.image} alt="User" className="w-10 h-10 rounded-full shadow-sm" />
              <div>
                <h1 className="text-lg font-semibold text-white">Call Center Dashboard</h1>
                <p className="text-xs text-gray-300">{currentUser.name} - Call Center Agent</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="p-2 text-white hover:text-gray-200 transition-colors duration-200"
              >
                <Bell size={18} />
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="p-2 text-white hover:text-gray-200 transition-colors duration-200"
              >
                <Settings size={18} />
              </motion.button>
            </div>
          </div>
        </header>

        <div className="flex space-x-6">
          <aside className="w-1/4">
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-sm font-semibold text-gray-700">Date Selection</h2>
              </div>
              <div className="flex items-center justify-between mb-2">
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => changeDate(-1)} 
                  className="p-1 rounded-full hover:bg-gray-100 text-gray-600"
                >
                  <ChevronLeft size={16} />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                  className="text-xs font-medium text-gray-600 hover:text-gray-800"
                >
                  {selectedDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                </motion.button>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => changeDate(1)} 
                  className="p-1 rounded-full hover:bg-gray-100 text-gray-600"
                >
                  <ChevronRight size={16} />
                </motion.button>
              </div>
              {isCalendarOpen && (
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date)
                    setIsCalendarOpen(false)
                  }}
                  inline
                  className="w-full"
                />
              )}
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              <h2 className="text-sm font-semibold text-gray-700 mb-4">Quick Stats</h2>
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-500">Total Tickets</span>
                  <span className="font-medium text-gray-700">{tickets.length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-500">Pending</span>
                  <span className="font-medium text-yellow-600">{tickets.filter(t => !t.solved).length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-500">Resolved</span>
                  <span className="font-medium text-green-600">{tickets.filter(t => t.solved).length}</span>
                </div>
              </div>
            </div>
          </aside>

          <main className="flex-1">
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-sm font-semibold text-gray-700">Tickets</h2>
                <div className="flex space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsModalOpen(true)}
                    className="bg-gray-600 text-white px-3 py-1 rounded-md text-xs hover:bg-gray-700 transition-colors duration-200 shadow-sm"
                  >
                    New Ticket
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsQueryManagementOpen(true)}
                    className="bg-gray-600 text-white px-3 py-1 rounded-md text-xs hover:bg-gray-700 transition-colors duration-200 shadow-sm flex items-center"
                  >
                    <ListPlus size={14} className="mr-1" />
                    Manage Queries
                  </motion.button>
                </div>
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search tickets..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 border rounded-md text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Case Number</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ticket</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PNR</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Query</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTickets.map((ticket) => (
                      <React.Fragment key={ticket.id}>
                        <motion.tr 
                          className="hover:bg-gray-50 transition-colors duration-150 cursor-pointer"
                          onClick={() => toggleExpandTicket(ticket.id)}
                          whileHover={{ backgroundColor: "#F3F4F6" }}
                        >
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{ticket.caseNumber}</td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{ticket.ticketNumber}</td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{ticket.pnr}</td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{ticket.query}</td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              ticket.solved ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                            }`}>
                              {ticket.solved ? 'Resolved' : 'Pending'}
                            </span>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs font-medium">
                            <motion.button 
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={(e) => { e.stopPropagation(); handleToggleSolve(ticket.id, ticket.solved); }} 
                              className="text-gray-600 hover:text-gray-900 mr-2 transition-colors duration-200"
                            >
                              {ticket.solved ? <CheckCircle size={14} /> : <Clock size={14} />}
                            </motion.button>
                            <motion.button 
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={(e) => { e.stopPropagation(); handleEdit(ticket); }} 
                              className="text-gray-600 hover:text-gray-900 mr-2 transition-colors duration-200"
                            >
                              <Edit size={14} />
                            </motion.button>
                            <motion.button 
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={(e) => { e.stopPropagation(); handleDelete(ticket.id); }} 
                              className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
                            >
                              <Trash size={14} />
                            </motion.button>
                          </td>
                        </motion.tr>
                        <AnimatePresence>
                          {expandedTicket === ticket.id && (
                            <motion.tr
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: 'auto' }}
                              exit={{ opacity: 0, height: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <td colSpan={6} className="px-4 py-3">
                                <div className="bg-gray-50 p-4 rounded-md overflow-hidden">
                                  <h4 className="font-semibold mb-2 text-xs">Ticket Details</h4>
                                  <p className="mb-1 text-xs"><strong>Case Number:</strong> {ticket.caseNumber}</p>
                                  <p className="mb-1 text-xs"><strong>Response:</strong></p>
                                  <div className="max-w-full overflow-hidden">
                                    <CollapsibleText text={ticket.response} maxLength={50} />
                                  </div>
                                  <p className="mb-1 text-xs"><strong>Created At:</strong> {ticket.timestamp.toDate().toLocaleString()}</p>
                                  <p className="mb-1 text-xs"><strong>Created By:</strong> {ticket.createdBy}</p>
                                  <p className="mb-1 text-xs"><strong>Origin:</strong> {ticket.origin}</p>
                                  <p className="mb-1 text-xs"><strong>Destination:</strong> {ticket.destination}</p>
                                </div>
                              </td>
                            </motion.tr>
                          )}
                        </AnimatePresence>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
            >
              <h2 className="text-sm font-semibold mb-4 text-gray-800">{editingTicket ? 'Edit Ticket' : 'New Ticket'}</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                {editingTicket && (
                  <div>
                    <label htmlFor="caseNumber" className="block text-xs font-medium text-gray-700">Case Number</label>
                    <input
                      type="text"
                      id="caseNumber"
                      name="caseNumber"
                      value={formData.caseNumber}
                      readOnly
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs bg-gray-100"
                    />
                  </div>
                )}
                <div>
                  <label htmlFor="ticketNumber" className="block text-xs font-medium text-gray-700">Ticket Number</label>
                  <input
                    type="text"
                    id="ticketNumber"
                    name="ticketNumber"
                    value={formData.ticketNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                  />
                </div>
                <div>
                  <label htmlFor="pnr" className="block text-xs font-medium text-gray-700">PNR</label>
                  <input
                    type="text"
                    id="pnr"
                    name="pnr"
                    value={formData.pnr}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                  />
                </div>
                <div>
                  <label htmlFor="query" className="block text-xs font-medium text-gray-700">Query</label>
                  <select
                    id="query"
                    name="query"
                    value={formData.query}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                  >
                    <option value="">Select a query</option>
                    {queries.map((query) => (
                      <option key={query.id} value={query.text}>{query.text}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="response" className="block text-xs font-medium text-gray-700">Response</label>
                  <textarea
                    id="response"
                    name="response"
                    value={formData.response}
                    onChange={handleInputChange}
                    rows={3}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                  ></textarea>
                </div>
                <div>
                  <label htmlFor="origin" className="block text-xs font-medium text-gray-700">Origin</label>
                  <input
                    type="text"
                    id="origin"
                    name="origin"
                    value={formData.origin}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                  />
                </div>
                <div>
                  <label htmlFor="destination" className="block text-xs font-medium text-gray-700">Destination</label>
                  <input
                    type="text"
                    id="destination"
                    name="destination"
                    value={formData.destination}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                  />
                </div>
                <div className="flex justify-end space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="px-3 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                  >
                    Cancel
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    className="px-3 py-1 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                  >
                    {editingTicket ? 'Update' : 'Create'}
                  </motion.button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isQueryManagementOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
            >
              <h2 className="text-sm font-semibold mb-4 text-gray-800">Manage Queries</h2>
              <div className="space-y-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsQueryManagementOpen(false)
                    setIsAddQueryModalOpen(true)
                  }}
                  className="w-full px-3 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                >
                  Add New Query
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsQueryManagementOpen(false)
                    setIsEditQueryModalOpen(true)
                  }}
                  className="w-full px-3 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                >
                  Edit Query
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsQueryManagementOpen(false)
                    setIsDeleteQueryModalOpen(true)
                  }}
                  className="w-full px-3 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                >
                  Delete Query
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsQueryManagementOpen(false)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                >
                  Close
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isAddQueryModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
            >
              <h2 className="text-sm font-semibold mb-4 text-gray-800">Add New Query</h2>
              <input
                type="text"
                value={newQuery}
                onChange={(e) => setNewQuery(e.target.value)}
                className="w-full p-2 border rounded-md text-xs mb-4 focus:outline-none focus:ring-2 focus:ring-gray-500"
                placeholder="Enter new query"
              />
              <div className="flex justify-end space-x-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsAddQueryModalOpen(false)}
                  className="px-3 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleAddQuery}
                  className="px-3 py-1 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                >
                  Add Query
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isEditQueryModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
            >
              <h2 className="text-sm font-semibold mb-4 text-gray-800">Edit Query</h2>
              <select
                value={editingQuery ? editingQuery.id : ''}
                onChange={(e) => {
                  const selectedQuery = queries.find(q => q.id === e.target.value)
                  setEditingQuery(selectedQuery)
                }}
                className="w-full p-2 border rounded-md text-xs mb-4 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                <option value="">Select a query to edit</option>
                {queries.filter(q => q.id !== 'predefined').map((query) => (
                  <option key={query.id} value={query.id}>{query.text}</option>
                ))}
              </select>
              {editingQuery && (
                <input
                  type="text"
                  value={editingQuery.text}
                  onChange={(e) => setEditingQuery({ ...editingQuery, text: e.target.value })}
                  className="w-full p-2 border rounded-md text-xs mb-4 focus:outline-none focus:ring-2 focus:ring-gray-500"
                  placeholder="Edit query"
                />
              )}
              <div className="flex justify-end space-x-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsEditQueryModalOpen(false)}
                  className="px-3 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleEditQuery}
                  className="px-3 py-1 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                >
                  Update Query
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isDeleteQueryModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
            >
              <h2 className="text-sm font-semibold mb-4 text-gray-800">Delete Query</h2>
              <select
                value={deletingQuery ? deletingQuery.id : ''}
                onChange={(e) => {
                  const selectedQuery = queries.find(q => q.id === e.target.value)
                  setDeletingQuery(selectedQuery)
                }}
                className="w-full p-2 border rounded-md text-xs mb-4 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                <option value="">Select a query to delete</option>
                {queries.filter(q => q.id !== 'predefined').map((query) => (
                  <option key={query.id} value={query.id}>{query.text}</option>
                ))}
              </select>
              {deletingQuery && (
                <p className="text-sm font-medium text-gray-800 mb-4">Are you sure you want to delete this query: "{deletingQuery.text}"?</p>
              )}
              <div className="flex justify-end space-x-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsDeleteQueryModalOpen(false)}
                  className="px-3 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleDeleteQuery}
                  className="px-3 py-1 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200"
                >
                  Delete Query
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}