'use client'

import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, Calendar, Sun, Moon, Plus, Trash, Edit, Check, X } from 'lucide-react';

const SHIFTS = {
  DAY: '06:00 - 18:00',
  NIGHT: '18:00 - 06:00'
};

const VACATION_DAYS = 23;
const HOURS_PER_SHIFT = 12;

const generateYearlyRoster = (employees, year, vacations) => {
  if (!employees || employees.length < 2) return [];

  const roster = [];
  const startDate = new Date(year, 0, 1);
  const endDate = new Date(year, 11, 31);
  const employeeCount = employees.length;

  let currentDate = new Date(startDate);
  let dayShiftIndex = 0;
  let nightShiftIndex = 1;

  while (currentDate <= endDate) {
    const dayEmployee = employees[dayShiftIndex];
    const nightEmployee = employees[nightShiftIndex];

    const isDayEmployeeOnVacation = vacations.some(v => 
      v.employeeId === dayEmployee.id && 
      currentDate >= new Date(v.startDate) && 
      currentDate <= new Date(v.endDate)
    );

    const isNightEmployeeOnVacation = vacations.some(v => 
      v.employeeId === nightEmployee.id && 
      currentDate >= new Date(v.startDate) && 
      currentDate <= new Date(v.endDate)
    );

    let availableDayEmployee = isDayEmployeeOnVacation ? null : dayEmployee;
    let availableNightEmployee = isNightEmployeeOnVacation ? null : nightEmployee;

    // If day employee is not available, find a replacement
    if (!availableDayEmployee) {
      availableDayEmployee = employees.find((emp, index) => 
        index !== nightShiftIndex && !vacations.some(v => 
          v.employeeId === emp.id && 
          currentDate >= new Date(v.startDate) && 
          currentDate <= new Date(v.endDate)
        )
      );
    }

    // If night employee is not available or is the same as day employee, find a replacement
    if (!availableNightEmployee || availableNightEmployee.id === availableDayEmployee.id) {
      availableNightEmployee = employees.find((emp, index) => 
        emp.id !== availableDayEmployee.id && !vacations.some(v => 
          v.employeeId === emp.id && 
          currentDate >= new Date(v.startDate) && 
          currentDate <= new Date(v.endDate)
        )
      );
    }

    roster.push({
      date: new Date(currentDate),
      dayShift: availableDayEmployee,
      nightShift: availableNightEmployee
    });

    // Update indices for next day
    dayShiftIndex = (dayShiftIndex + 1) % employeeCount;
    nightShiftIndex = (nightShiftIndex + 1) % employeeCount;

    // Ensure day and night shift employees are different
    if (nightShiftIndex === dayShiftIndex) {
      nightShiftIndex = (nightShiftIndex + 1) % employeeCount;
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return roster;
};

const EmployeeManager = ({ employees, setEmployees, vacations, setVacations }) => {
  const [newEmployee, setNewEmployee] = useState('');
  const [editingEmployee, setEditingEmployee] = useState(null);

  const handleAddEmployee = () => {
    if (newEmployee.trim()) {
      setEmployees([...employees, { id: Date.now().toString(), name: newEmployee.trim() }]);
      setNewEmployee('');
    }
  };

  const handleRemoveEmployee = (id) => {
    setEmployees(employees.filter(emp => emp.id !== id));
    setVacations(vacations.filter(v => v.employeeId !== id));
  };

  const handleEditEmployee = (employee) => {
    setEditingEmployee({ ...employee });
  };

  const handleSaveEdit = () => {
    setEmployees(employees.map(emp => emp.id === editingEmployee.id ? editingEmployee : emp));
    setEditingEmployee(null);
  };

  const handleCancelEdit = () => {
    setEditingEmployee(null);
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow p-4 mb-4">
      <h3 className="text-xl font-bold mb-4 text-white">Manage Employees</h3>
      <div className="flex mb-4">
        <input
          type="text"
          value={newEmployee}
          onChange={(e) => setNewEmployee(e.target.value)}
          className="flex-grow p-2 rounded-l bg-gray-700 text-white"
          placeholder="New employee name"
        />
        <button
          onClick={handleAddEmployee}
          className="bg-blue-500 text-white p-2 rounded-r hover:bg-blue-600 transition-colors"
        >
          <Plus size={20} />
        </button>
      </div>
      <ul className="space-y-2">
        {employees.map(employee => (
          <li key={employee.id} className="flex items-center justify-between bg-gray-700 p-2 rounded">
            {editingEmployee && editingEmployee.id === employee.id ? (
              <>
                <input
                  type="text"
                  value={editingEmployee.name}
                  onChange={(e) => setEditingEmployee({ ...editingEmployee, name: e.target.value })}
                  className="flex-grow p-1 rounded bg-gray-600 text-white"
                />
                <div>
                  <button onClick={handleSaveEdit} className="text-green-500 mr-2"><Check size={20} /></button>
                  <button onClick={handleCancelEdit} className="text-red-500"><X size={20} /></button>
                </div>
              </>
            ) : (
              <>
                <span className="text-white">{employee.name}</span>
                <div>
                  <button onClick={() => handleEditEmployee(employee)} className="text-blue-500 mr-2"><Edit size={20} /></button>
                  <button onClick={() => handleRemoveEmployee(employee.id)} className="text-red-500"><Trash size={20} /></button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const VacationManager = ({ employees, vacations, setVacations, onVacationChange }) => {
  const [newVacation, setNewVacation] = useState({ employeeId: '', startDate: '', endDate: '' });

  const handleAddVacation = () => {
    if (newVacation.employeeId && newVacation.startDate && newVacation.endDate) {
      const updatedVacations = [...vacations, { ...newVacation, id: Date.now().toString() }];
      setVacations(updatedVacations);
      setNewVacation({ employeeId: '', startDate: '', endDate: '' });
      onVacationChange(updatedVacations);
    }
  };

  const handleRemoveVacation = (id) => {
    const updatedVacations = vacations.filter(v => v.id !== id);
    setVacations(updatedVacations);
    onVacationChange(updatedVacations);
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow p-4 mb-4">
      <h3 className="text-xl font-bold mb-4 text-white">Manage Vacations</h3>
      <div className="grid grid-cols-4 gap-2 mb-4">
        <select
          value={newVacation.employeeId}
          onChange={(e) => setNewVacation({ ...newVacation, employeeId: e.target.value })}
          className="p-2 rounded bg-gray-700 text-white"
        >
          <option value="">Select Employee</option>
          {employees.map(emp => (
            <option key={emp.id} value={emp.id}>{emp.name}</option>
          ))}
        </select>
        <input
          type="date"
          value={newVacation.startDate}
          onChange={(e) => setNewVacation({ ...newVacation, startDate: e.target.value })}
          className="p-2 rounded bg-gray-700 text-white"
        />
        <input
          type="date"
          value={newVacation.endDate}
          onChange={(e) => setNewVacation({ ...newVacation, endDate: e.target.value })}
          className="p-2 rounded bg-gray-700 text-white"
        />
        <button
          onClick={handleAddVacation}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors"
        >
          Add Vacation
        </button>
      </div>
      <ul className="space-y-2">
        {vacations.map(vacation => (
          <li key={vacation.id} className="flex items-center justify-between bg-gray-700 p-2 rounded">
            <span className="text-white">
              {employees.find(emp => emp.id === vacation.employeeId)?.name}: 
              {new Date(vacation.startDate).toLocaleDateString()} - 
              {new Date(vacation.endDate).toLocaleDateString()}
            </span>
            <button onClick={() => handleRemoveVacation(vacation.id)} className="text-red-500">
              <Trash size={20} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const MonthView = ({ roster, currentDate, onChangeShift, employees }) => {
  const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const startDate = new Date(monthStart);
  startDate.setDate(startDate.getDate() - startDate.getDay());
  const endDate = new Date(monthEnd);
  endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));

  const weeks = [];
  let currentWeek = [];

  for (let day = new Date(startDate); day <= endDate; day.setDate(day.getDate() + 1)) {
    const rosterDay = roster.find(r => r.date.toDateString() === day.toDateString());
    currentWeek.push(rosterDay || { date: new Date(day), dayShift: null, nightShift: null });
    
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  }

  const handleShiftChange = (date, shift, newEmployeeId) => {
    const newEmployee = employees.find(emp => emp.id === newEmployeeId);
    onChangeShift(date, shift, newEmployee);
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow overflow-hidden">
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <th key={day} className="p-2 border-b border-gray-700 text-gray-400">
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week.map((day, dayIndex) => (
                <td key={dayIndex} className={`p-2 border border-gray-700 ${day.date.getMonth() !== currentDate.getMonth() ? 'bg-gray-900 text-gray-600' : 'text-white'}`}>
                  <div className="font-bold mb-1">{day.date.getDate()}</div>
                  <div className="text-xs">
                    <div className="mb-1">
                      <div className="flex items-center justify-between">
                        <Sun size={16} className="text-yellow-500" />
                        <span>Day Shift</span>
                      </div>
                      <select
                        value={day.dayShift?.id || ''}
                        onChange={(e) => handleShiftChange(day.date, 'dayShift', e.target.value)}
                        className="w-full mt-1 bg-gray-700 text-white rounded"
                      >
                        <option value="">Select Employee</option>
                        {employees.map(employee => (
                          <option key={employee.id} value={employee.id}>{employee.name}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div className="flex items-center justify-between">
                        <Moon size={16} className="text-blue-300" />
                        <span>Night Shift</span>
                      </div>
                      <select
                        value={day.nightShift?.id || ''}
                        onChange={(e) => handleShiftChange(day.date, 'nightShift', e.target.value)}
                        className="w-full mt-1 bg-gray-700 text-white rounded"
                      >
                        <option value="">Select Employee</option>
                        {employees.map(employee => (
                          <option key={employee.id} value={employee.id}>{employee.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AnnualView = ({ roster, employees, vacations }) => {
  const employeeStats = useMemo(() => {
    if (!employees || employees.length === 0 || !roster || roster.length === 0) return [];

    return employees.map(employee => {
      const dayShifts = roster.filter(day => day.dayShift && day.dayShift.id === employee.id).length;
      const nightShifts = roster.filter(day => day.nightShift && day.nightShift.id === employee.id).length;
      const workedHours = (dayShifts + nightShifts) * HOURS_PER_SHIFT;
      const employeeVacations = vacations.filter(v => v.employeeId === employee.id);
      const vacationDaysTaken = employeeVacations.reduce((total, vacation) => {
        const start = new Date(vacation.startDate);
        const end = new Date(vacation.endDate);
        return total + Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
      }, 0);
      const vacationDaysLeft = VACATION_DAYS - vacationDaysTaken;

      return {
        ...employee,
        dayShifts,
        nightShifts,
        workedHours,
        vacationDaysTaken,
        vacationDaysLeft
      };
    });
  }, [roster, employees, vacations]);

  if (employeeStats.length === 0) {
    return <div className="text-white">No data available</div>;
  }

  return (
    <div className="bg-gray-800 rounded-lg shadow overflow-hidden p-4">
      <h3 className="text-xl font-bold mb-4 text-white">Annual Employee Statistics</h3>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left p-2 text-gray-400">Employee</th>
            <th className="text-left p-2 text-gray-400">Day Shifts</th>
            <th className="text-left p-2 text-gray-400">Night Shifts</th>
            <th className="text-left p-2 text-gray-400">Worked Hours</th>
            <th className="text-left p-2 text-gray-400">Vacation Days Taken</th>
            <th className="text-left p-2 text-gray-400">Vacation Days Left</th>
          </tr>
        </thead>
        <tbody>
          {employeeStats.map(employee => (
            <tr key={employee.id}>
              <td className="p-2 text-white">{employee.name}</td>
              <td className="p-2 text-white">{employee.dayShifts}</td>
              <td className="p-2 text-white">{employee.nightShifts}</td>
              <td className="p-2 text-white">{employee.workedHours}</td>
              <td className="p-2 text-white">{employee.vacationDaysTaken}</td>
              <td className="p-2 text-white">{employee.vacationDaysLeft}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default function RosterSystem() {
  const [employees, setEmployees] = useState([]);
  const [roster, setRoster] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('month');
  const [vacations, setVacations] = useState([]);

  useEffect(() => {
    const initialEmployees = [
      { id: '1', name: 'Ana García' },
      { id: '2', name: 'Carlos Rodríguez' },
      { id: '3', name: 'Elena Martínez' },
      { id: '4', name: 'David López' },
      { id: '5', name: 'Sofía Fernández' },
    ];
    setEmployees(initialEmployees);
  }, []);

  useEffect(() => {
    if (employees.length > 0) {
      const yearlyRoster = generateYearlyRoster(employees, currentDate.getFullYear(), vacations);
      setRoster(yearlyRoster);
    }
  }, [employees, currentDate, vacations]);

  const handleChangeMonth = (increment) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCurrentDate(newDate);
  };

  const handleChangeShift = (date, shift, newEmployee) => {
    const updatedRoster = [...roster];
    const dayIndex = updatedRoster.findIndex(day => day.date.toDateString() === date.toDateString());
    
    if (dayIndex === -1) return;

    // Update the current day's shift
    updatedRoster[dayIndex] = { ...updatedRoster[dayIndex], [shift]: newEmployee };

    // Check and update previous day's night shift if necessary
    if (shift === 'dayShift' && dayIndex > 0) {
      const prevDay = updatedRoster[dayIndex - 1];
      if (prevDay.nightShift && prevDay.nightShift.id === newEmployee.id) {
        updatedRoster[dayIndex - 1] = { ...prevDay, nightShift: null };
      }
    }

    // Check and update next day's day shift if necessary
    if (shift === 'nightShift' && dayIndex < updatedRoster.length - 1) {
      const nextDay = updatedRoster[dayIndex + 1];
      if (nextDay.dayShift && nextDay.dayShift.id === newEmployee.id) {
        updatedRoster[dayIndex + 1] = { ...nextDay, dayShift: null };
      }
    }

    setRoster(updatedRoster);
  };

  const handleVacationChange = (updatedVacations) => {
    const yearlyRoster = generateYearlyRoster(employees, currentDate.getFullYear(), updatedVacations);
    setRoster(yearlyRoster);
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h2 className="text-3xl font-bold text-white mb-6">Roster System</h2>
      
      <EmployeeManager 
        employees={employees} 
        setEmployees={setEmployees}
        vacations={vacations}
        setVacations={setVacations}
      />
      
      <VacationManager 
        employees={employees}
        vacations={vacations}
        setVacations={setVacations}
        onVacationChange={handleVacationChange}
      />
      
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setViewMode(viewMode === 'month' ? 'year' : 'month')}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            {viewMode === 'month' ? <Calendar className="h-5 w-5" /> : 'Monthly View'}
          </button>
          {viewMode === 'month' && (
            <>
              <button
                onClick={() => handleChangeMonth(-1)}
                className="bg-gray-700 text-white p-2 rounded hover:bg-gray-600 transition-colors"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <span className="text-white font-medium">
                {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </span>
              <button
                onClick={() => handleChangeMonth(1)}
                className="bg-gray-700 text-white p-2 rounded hover:bg-gray-600 transition-colors"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </>
          )}
        </div>
      </div>
      
      {viewMode === 'month' ? (
        <MonthView
          roster={roster}
          currentDate={currentDate}
          onChangeShift={handleChangeShift}
          employees={employees}
        />
      ) : (
        <AnnualView roster={roster} employees={employees} vacations={vacations} />
      )}
    </div>
  );
}