'use client'

import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, getDocs, doc, query, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { Edit, Trash2, Plus, X } from 'lucide-react';
import styled from 'styled-components';

const SlideContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  padding: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const ClashCard = styled.div`
  background: white;
  width: 350px;
  border-radius: 19px;
  position: relative;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.2);
  }
`;

const ClashCardImage = styled.div`
  position: relative;
  height: 200px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background: #f0f0f0;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 110%;
    max-width: none;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  &:hover img {
    transform: translateX(-50%) scale(1.05);
  }
`;

const ClashCardContent = styled.div`
  position: relative;
  z-index: 1;
  background: white;
  border-radius: 19px;
  padding-top: 20px;
`;

const ClashCardLevel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  color: #27732e;
`;

const ClashCardUnitName = styled.div`
  font-size: 28px;
  color: #333;
  font-weight: 900;
  margin: 10px 0;
`;

const ClashCardUnitStats = styled.div`
  color: white;
  font-weight: 700;
  background: #27732e;
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;

const StatColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 70%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const Stat = styled.div`
  font-size: 24px;
  line-height: 1;
  margin-bottom: 5px;
`;

const StatValue = styled.div`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  line-height: 1;
`;

const ClashCardUnitActions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  z-index: 20;
`;

const Button = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const IconButton = styled(Button)`
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const AddAircraftButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export default function Matriculas() {
  const [aircrafts, setAircrafts] = useState([]);
  const [formData, setFormData] = useState({
    matricula: '',
    anioFabricacion: '',
    configuracionW: '',
    configuracionY: '',
    tipoAvion: ''
  });
  const [editingId, setEditingId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchAircrafts();
  }, []);

  const fetchAircrafts = async () => {
    const matriculasCollection = collection(db, 'matriculas');
    const matriculasQuery = query(matriculasCollection, limit(32));
    const matriculasSnapshot = await getDocs(matriculasQuery);
    const aircraftList = matriculasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setAircrafts(aircraftList);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingId) {
      await updateAircraft(editingId);
    } else {
      await addAircraft();
    }
  };

  const addAircraft = async () => {
    const newAircraftData = {
      matricula: formData.matricula,
      anioFabricacion: parseInt(formData.anioFabricacion),
      configuracionW: parseInt(formData.configuracionW),
      configuracionY: parseInt(formData.configuracionY),
      tipoAvion: formData.tipoAvion
    };
    await addDoc(collection(db, 'matriculas'), newAircraftData);
    setIsModalOpen(false);
    setFormData({ matricula: '', anioFabricacion: '', configuracionW: '', configuracionY: '', tipoAvion: '' });
    fetchAircrafts();
  };

  const updateAircraft = async (id) => {
    const aircraftRef = doc(db, 'matriculas', id);
    const updateData = { 
      matricula: formData.matricula,
      anioFabricacion: parseInt(formData.anioFabricacion),
      configuracionW: parseInt(formData.configuracionW),
      configuracionY: parseInt(formData.configuracionY),
      tipoAvion: formData.tipoAvion
    };
    await updateDoc(aircraftRef, updateData);
    setIsModalOpen(false);
    setEditingId(null);
    setFormData({ matricula: '', anioFabricacion: '', configuracionW: '', configuracionY: '', tipoAvion: '' });
    fetchAircrafts();
  };

  const deleteAircraft = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este avión?')) {
      await deleteDoc(doc(db, 'matriculas', id));
      fetchAircrafts();
    }
  };

  const openEditModal = (aircraft) => {
    setFormData({
      matricula: aircraft.matricula,
      anioFabricacion: aircraft.anioFabricacion.toString(),
      configuracionW: aircraft.configuracionW.toString(),
      configuracionY: aircraft.configuracionY.toString(),
      tipoAvion: aircraft.tipoAvion || ''
    });
    setEditingId(aircraft.id);
    setIsModalOpen(true);
  };

  return (
    <SlideContainer>
      <AddAircraftButton
        onClick={() => {
          setFormData({ matricula: '', anioFabricacion: '', configuracionW: '', configuracionY: '', tipoAvion: '' });
          setEditingId(null);
          setIsModalOpen(true);
        }}
      >
        <Plus size={24} /> Añadir Avión
      </AddAircraftButton>
      <Wrapper>
        {aircrafts.map((aircraft) => (
          <ClashCard key={aircraft.id}>
            <ClashCardImage>
              <img
                src={`/planes/${aircraft.matricula}.png`}
                alt={aircraft.matricula}
              />
            </ClashCardImage>
            <ClashCardContent>
              <ClashCardLevel>Año {aircraft.anioFabricacion}</ClashCardLevel>
              <ClashCardUnitName>{aircraft.matricula}</ClashCardUnitName>
              <ClashCardLevel>{aircraft.tipoAvion || 'Tipo no especificado'}</ClashCardLevel>
              <ClashCardUnitStats>
                <StatColumn>
                  <Stat>{aircraft.configuracionW}</Stat>
                  <StatValue>Premium</StatValue>
                </StatColumn>
                <StatColumn>
                  <Stat>{aircraft.configuracionY}</Stat>
                  <StatValue>Turista</StatValue>
                </StatColumn>
                <StatColumn>
                  <Stat>{aircraft.configuracionW + aircraft.configuracionY}</Stat>
                  <StatValue>Total</StatValue>
                </StatColumn>
              </ClashCardUnitStats>
            </ClashCardContent>
            <ClashCardUnitActions>
              <IconButton onClick={() => openEditModal(aircraft)}>
                <Edit size={18} />
              </IconButton>
              <IconButton onClick={() => deleteAircraft(aircraft.id)}>
                <Trash2 size={18} />
              </IconButton>
            </ClashCardUnitActions>
          </ClashCard>
        ))}
      </Wrapper>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <h2>{editingId ? 'Editar Avión' : 'Añadir Avión'}</h2>
            <StyledForm onSubmit={handleSubmit}>
              <Input
                type="text"
                name="matricula"
                value={formData.matricula}
                onChange={handleInputChange}
                placeholder="Matrícula"
                required
              />
              <Input
                type="number"
                name="anioFabricacion"
                value={formData.anioFabricacion}
                onChange={handleInputChange}
                placeholder="Año de Fabricación"
                required
              />
              <Input
                type="number"
                name="configuracionW"
                value={formData.configuracionW}
                onChange={handleInputChange}
                placeholder="Configuración Premium"
                required
              />
              <Input
                type="number"
                name="configuracionY"
                value={formData.configuracionY}
                onChange={handleInputChange}
                placeholder="Configuración Turista"
                required
              />
              <Input
                type="text"
                name="tipoAvion"
                value={formData.tipoAvion}
                onChange={handleInputChange}
                placeholder="Tipo de Avión"
                required
              />
              <Button type="submit">
                {editingId ? 'Actualizar' : 'Añadir'}
              </Button>
            </StyledForm>
            <Button onClick={() => setIsModalOpen(false)}>
              <X size={24} /> Cerrar
            </Button>
          </ModalContent>
        </Modal>
      )}
    </SlideContainer>
  );
}