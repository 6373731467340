'use client'

import React, { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns'; // Asegurar que 'format' esté importado
import { motion, AnimatePresence } from 'framer-motion';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Plus, Pencil, Trash, Search, Calendar as CalendarIcon, RotateCw, Plane, AlertTriangle, XCircle } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// Asegúrate de que las rutas de los componentes UI estén bien
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { Info, Cloud } from 'lucide-react';

// Cajas adicionales
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";

// Función para calcular el retraso en minutos
const calculateDelay = (scheduled, actual) => {
  if (!scheduled || !actual) return null;
  const scheduledTime = new Date(`1970-01-01T${scheduled}`);
  const actualTime = new Date(`1970-01-01T${actual}`);
  const delayInMinutes = Math.round((actualTime - scheduledTime) / 60000);
  return delayInMinutes > 0 ? delayInMinutes : null;
};

export default function FlightsScreen() {
  const [flights, setFlights] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [formData, setFormData] = useState({
    flightNumber: '',
    origin: '',
    destination: '',
    date: new Date(),
    std: '',
    realTimeDeparture: '',
    sta: '',
    realTimeArrival: '',
    delayCode1: '',
    delayComment1: '',
    delayCode2: '',
    delayComment2: '',
    delayCode3: '',
    delayComment3: '',
    isCancelled: false
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFlights();
  }, [selectedDate]);

  const fetchFlights = async () => {
    setLoading(true);
    try {
      const flightsCollection = collection(db, 'flights');
      const startOfDay = new Date(selectedDate.setHours(0, 0, 0, 0));
      const endOfDay = new Date(selectedDate.setHours(23, 59, 59, 999));
      const q = query(flightsCollection, where('date', '>=', startOfDay), where('date', '<=', endOfDay));
      const flightSnapshot = await getDocs(q);
      const flightList = flightSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), date: doc.data().date.toDate() }));
      setFlights(flightList);
    } catch (error) {
      console.error("Error fetching flights: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Cálculo de estadísticas (totales)
  const totals = useMemo(() => {
    return flights.reduce((acc, flight) => {
      acc.totalFlights++;
      if (flight.isCancelled) {
        acc.cancelledFlights++;
      } else {
        const departureDelay = calculateDelay(flight.std, flight.realTimeDeparture);
        const arrivalDelay = calculateDelay(flight.sta, flight.realTimeArrival);
        if (!departureDelay && !arrivalDelay) {
          acc.onTimeFlights++;
        } else {
          acc.delayedFlights++;
        }
      }
      return acc;
    }, { totalFlights: 0, onTimeFlights: 0, delayedFlights: 0, cancelledFlights: 0 });
  }, [flights]);

  const handleInputChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date: date });
  };

  const handleAddFlight = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'flights'), formData);
      setFormData({
        flightNumber: '',
        origin: '',
        destination: '',
        date: new Date(),
        std: '',
        realTimeDeparture: '',
        sta: '',
        realTimeArrival: '',
        delayCode1: '',
        delayComment1: '',
        delayCode2: '',
        delayComment2: '',
        delayCode3: '',
        delayComment3: '',
        isCancelled: false
      });
      setIsAdding(false);
      fetchFlights();
    } catch (error) {
      console.error("Error adding flight: ", error);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (selectedFlight) {
      try {
        const flightRef = doc(db, 'flights', selectedFlight.id);
        await updateDoc(flightRef, formData);
        setSelectedFlight(null);
        fetchFlights();
      } catch (error) {
        console.error("Error updating flight: ", error);
      }
    }
  };

  const handleDeleteFlight = async (flightId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este vuelo?')) {
      try {
        await deleteDoc(doc(db, 'flights', flightId));
        fetchFlights();
      } catch (error) {
        console.error("Error deleting flight: ", error);
      }
    }
  };

  const filteredFlights = flights.filter(flight =>
    Object.values(flight).some(value => 
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const FlightCard = ({ flight, onEdit, onDelete }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const departureDelay = calculateDelay(flight.std, flight.realTimeDeparture);
    const arrivalDelay = calculateDelay(flight.sta, flight.realTimeArrival);

    const isOnTime = !departureDelay && !arrivalDelay && !flight.isCancelled;

    const destinationImages = {
      EZE: "https://americasquarterly.org/wp-content/uploads/2016/10/Argentina_Top.jpg",
      LAX: "https://www.civitatis.com/f/estados-unidos/los-angeles/los-angeles-m.jpg",
      SFO: "https://wallpapers.com/images/featured/horizonte-de-san-francisco-1ihpn12wdtk5xa9h.jpg",
      SCL: "https://www.blogdelfotografo.com/wp-content/uploads/2023/01/vista-panoramica-santiago-chile.webp",
      JFK: "https://www.vacacionesnuevayork.com/wp-content/uploads/2019/02/new-york.jpg",
      BOS: "https://content.r9cdn.net/rimg/dimg/8d/d4/5837febe-city-25588-16b90080f1e.jpg?width=1366&height=768&xhint=2723&yhint=1685&crop=true",
      BCN: "https://www.civitatis.com/blog/wp-content/uploads/2024/02/shutterstock_1891578742-1920x867.jpg",
      MIA: "https://www.viajesyfotografia.com/wp-content/uploads/2014/09/art-deco-district-miami-beach.jpg"
    };

    const destinationImage = destinationImages[flight.destination] || `/placeholder.svg?height=120&width=260`;

    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
        className={`boarding-pass bg-gray-800 rounded-xl shadow-lg overflow-hidden w-[260px] h-[420px] text-gray-200 uppercase relative ${flight.isCancelled ? 'bg-red-900' : ''}`}
        style={{
          perspective: '1000px',
          transformStyle: 'preserve-3d'
        }}
      >
        <motion.div
          animate={{ rotateY: isFlipped ? 180 : 0 }}
          transition={{ duration: 0.6 }}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            transformStyle: 'preserve-3d'
          }}
        >
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden'
          }}>
            <header className="bg-gradient-to-b from-gray-700 to-gray-800 p-2 h-[48px] flex justify-between items-center">
              <div className="relative w-[80px] h-[24px]">
                <img
                  src="/logo.png"
                  alt="Level Airlines Logo"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="text-right text-white">
                <small className="block text-[7px] opacity-80 mb-0.5">flight</small>
                <strong className="block text-base">{flight.flightNumber}</strong>
              </div>
            </header>

            <div className="relative h-[120px] overflow-hidden">
              <img
                src={destinationImage}
                alt={`${flight.destination} cityscape`}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <h3 className="text-white text-2xl font-bold">{flight.destination}</h3>
              </div>
            </div>

            <section className="cities relative py-4 px-3 flex justify-between">
              <div className="city">
                <small className="block text-[10px] text-gray-400 mb-0.5">{flight.origin}</small>
                <strong className="block text-3xl font-light">{flight.origin.slice(0, 3).toUpperCase()}</strong>
              </div>
              <motion.div
                animate={{
                  y: [0, -8, 0],
                  rotate: [0, 5, 0, -5, 0],
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <Plane className="absolute w-[24px] h-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" />
              </motion.div>
              <div className="city">
                <small className="block text-[10px] text-gray-400 mb-0.5">{flight.destination}</small>
                <strong className="block text-3xl font-light">{flight.destination.slice(0, 3).toUpperCase()}</strong>
              </div>
            </section>

            <section className="infos flex border-t border-gray-700">
              <div className="times w-1/2 p-2">
                <div className="box w-1/2 float-left p-2">
                  <small className="block text-[9px] text-gray-400">Departure</small>
                  <strong className="block text-[12px] origin-left scale-90">{flight.std}</strong>
                </div>
                <div className="box w-1/2 float-left p-2">
                  <small className="block text-[9px] text-gray-400">Actual</small>
                  <strong className="block text-[12px] origin-left scale-90">{flight.realTimeDeparture || 'N/A'}</strong>
                </div>
                <div className="box w-1/2 float-left p-2">
                  <small className="block text-[9px] text-gray-400">Arrival</small>
                  <strong className="block text-[12px] origin-left scale-90">{flight.sta}</strong>
                </div>
                <div className="box w-1/2 float-left p-2">
                  <small className="block text-[9px] text-gray-400">Actual</small>
                  <strong className="block text-[12px] origin-left scale-90">{flight.realTimeArrival || 'N/A'}</strong>
                </div>
              </div>
              <div className="status w-1/2 p-2 flex items-center justify-center">
                {flight.isCancelled ? (
                  <motion.div 
                    className="text-center"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                  >
                    <p className="text-red-500 font-bold text-sm">CANCELLED</p>
                  </motion.div>
                ) : isOnTime ? (
                  <motion.div 
                    className="text-center"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                  >
                    <p className="text-green-500 font-bold text-sm">ON TIME</p>
                  </motion.div>
                ) : (
                  <motion.div 
                    className="text-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {departureDelay && (
                      <p className="text-red-500 text-xs">
                        Dep. Delay: {departureDelay} min
                      </p>
                    )}
                    {arrivalDelay && (
                      <p className="text-red-500 text-xs">
                        Arr. Delay: {arrivalDelay} min
                      </p>
                    )}
                  </motion.div>
                )}
              </div>
            </section>

            <section className="strap relative border-t border-gray-700 p-4">
              <div className="box">
                <div className="date mb-2">
                  <small className="block text-[9px] text-gray-400">Date</small>
                  <strong className="block text-[12px]">{flight.date.toLocaleDateString('es-ES', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</strong>
                </div>
              </div>
            </section>

            <div className="absolute bottom-2 right-2 flex space-x-1">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setIsFlipped(!isFlipped)}
                className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors duration-200"
              >
                <Info className="h-3 w-3 text-gray-300" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => onEdit(flight)}
                className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors duration-200"
              >
                <Pencil className="h-3 w-3 text-gray-300" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => onDelete(flight.id)}
                className="p-1 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors duration-200"
              >
                <Trash className="h-3 w-3 text-gray-300" />
              </motion.button>
            </div>
          </div>

          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backfaceVisibility: 'hidden',
            transform: 'rotateY(180deg)',
            backgroundColor: '#1F2937',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <h3 className="text-lg font-bold mb-4 text-gray-200">Delay Information</h3>
            {flight.delayCode1 && (
              <div className="mb-2">
                <p className="text-sm font-semibold text-gray-300">Delay Code 1: {flight.delayCode1}</p>
                <p className="text-xs text-gray-400">{flight.delayComment1}</p>
              </div>
            )}
            {flight.delayCode2 && (
              <div className="mb-2">
                <p className="text-sm font-semibold text-gray-300">Delay Code 2: {flight.delayCode2}</p>
                <p className="text-xs text-gray-400">{flight.delayComment2}</p>
              </div>
            )}
            {flight.delayCode3 && (
              <div className="mb-2">
                <p className="text-sm font-semibold text-gray-300">Delay Code 3: {flight.delayCode3}</p>
                <p className="text-xs text-gray-400">{flight.delayComment3}</p>
              </div>
            )}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsFlipped(!isFlipped)}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
            >
              Back to Flight Info
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  const renderForm = () => (
    <motion.form
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
      onSubmit={selectedFlight ? handleEditSubmit : handleAddFlight}
      className="space-y-4 bg-gray-100 p-6 rounded-lg shadow-xl"
    >
      <Input
        type="text"
        name="flightNumber"
        value={formData.flightNumber}
        onChange={handleInputChange}
        placeholder="Número de vuelo"
        className="w-full"
        required
      />
      <div className="flex space-x-4">
        <Input
          type="text"
          name="origin"
          value={formData.origin}
          onChange={handleInputChange}
          placeholder="Origen"
          className="w-1/2"
          required
        />
        <Input
          type="text"
          name="destination"
          value={formData.destination}
          onChange={handleInputChange}
          placeholder="Destino"
          className="w-1/2"
          required
        />
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={`w-full justify-start text-left font-normal ${!formData.date && "text-muted-foreground"}`}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {formData.date ? format(formData.date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <DatePicker
            selected={formData.date}
            onSelect={handleDateChange}
            className="bg-white"
          />
        </PopoverContent>
      </Popover>
      <div className="grid grid-cols-2 gap-4">
        <Input
          type="time"
          name="std"
          value={formData.std}
          onChange={handleInputChange}
          placeholder="STD"
          className="w-full"
          required
        />
        <Input
          type="time"
          name="realTimeDeparture"
          value={formData.realTimeDeparture}
          onChange={handleInputChange}
          placeholder="Salida Real"
          className="w-full"
        />
        <Input
          type="time"
          name="sta"
          value={formData.sta}
          onChange={handleInputChange}
          placeholder="STA"
          className="w-full"
          required
        />
        <Input
          type="time"
          name="realTimeArrival"
          value={formData.realTimeArrival}
          onChange={handleInputChange}
          placeholder="Llegada Real"
          className="w-full"
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          type="text"
          name="delayCode1"
          value={formData.delayCode1}
          onChange={handleInputChange}
          placeholder="Código de retraso 1"
          className="w-full"
        />
        <Input
          type="text"
          name="delayComment1"
          value={formData.delayComment1}
          onChange={handleInputChange}
          placeholder="Comentario de retraso 1"
          className="w-full"
        />
        <Input
          type="text"
          name="delayCode2"
          value={formData.delayCode2}
          onChange={handleInputChange}
          placeholder="Código de retraso 2"
          className="w-full"
        />
        <Input
          type="text"
          name="delayComment2"
          value={formData.delayComment2}
          onChange={handleInputChange}
          placeholder="Comentario de retraso 2"
          className="w-full"
        />
        <Input
          type="text"
          name="delayCode3"
          value={formData.delayCode3}
          onChange={handleInputChange}
          placeholder="Código de retraso 3"
          className="w-full"
        />
        <Input
          type="text"
          name="delayComment3"
          value={formData.delayComment3}
          onChange={handleInputChange}
          placeholder="Comentario de retraso 3"
          className="w-full"
        />
      </div>
      <div className="flex items-center">
        <Input
          type="checkbox"
          name="isCancelled"
          checked={formData.isCancelled}
          onChange={handleInputChange}
          className="mr-2"
        />
        <label htmlFor="isCancelled" className="text-gray-300">Vuelo cancelado</label>
      </div>
      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            setSelectedFlight(null);
            setIsAdding(false);
            setFormData({
              flightNumber: '',
              origin: '',
              destination: '',
              date: new Date(),
              std: '',
              realTimeDeparture: '',
              sta: '',
              realTimeArrival: '',
              delayCode1: '',
              delayComment1: '',
              delayCode2: '',
              delayComment2: '',
              delayCode3: '',
              delayComment3: '',
              isCancelled: false
            });
          }}
        >
          Cancelar
        </Button>
        <Button type="submit">
          {selectedFlight ? 'Actualizar Vuelo' : 'Añadir Vuelo'}
        </Button>
      </div>
    </motion.form>
  );

  return (
    <div className="bg-slate-00 min-h-screen p-8 transition-all duration-500">
      <div className="max-w-7xl mx-auto">
       

        {/* Elementos de búsqueda, calendario, y añadir vuelo */}
        <div className="mb-8 flex flex-wrap justify-between items-center gap-4">
          <div className="flex-1 min-w-[200px]">
            <div className="relative">
              <Input
                type="text"
                placeholder="Buscar vuelos..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>

          <div className="flex-1 min-w-[200px]">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={`w-full justify-start text-left font-normal ${!selectedDate && "text-muted-foreground"}`}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {selectedDate ? format(selectedDate, "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <DatePicker
                  selected={selectedDate}
                  onSelect={(date) => {
                    setSelectedDate(date);
                    fetchFlights();
                  }}
                  className="bg-white"
                />
              </PopoverContent>
            </Popover>
          </div>

          <Button onClick={() => setIsAdding(true)}>
            <Plus className="h-5 w-5 mr-2" />
            Add Flight
          </Button>
        </div>

        {/* Cajas de estadísticas */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <Card className="relative overflow-hidden">
            <div className="relative bg-gradient-to-r from-blue-600/80 to-blue-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Total Flights</CardTitle>
                <Plane className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.totalFlights}</div>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="relative bg-gradient-to-r from-green-600/80 to-green-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">On-Time Flights</CardTitle>
                <RotateCw className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.onTimeFlights}</div>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="relative bg-gradient-to-r from-yellow-600/80 to-yellow-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Delayed Flights</CardTitle>
                <AlertTriangle className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.delayedFlights}</div>
              </CardContent>
            </div>
          </Card>
          <Card className="relative overflow-hidden">
            <div className="relative bg-gradient-to-r from-red-600/80 to-red-600/40 text-white p-4">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Cancelled Flights</CardTitle>
                <XCircle className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{totals.cancelledFlights}</div>
              </CardContent>
            </div>
          </Card>
        </div>

        <AnimatePresence>
          {(isAdding || selectedFlight) && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mb-8"
            >
              {renderForm()}
            </motion.div>
          )}
        </AnimatePresence>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            >
              <RotateCw className="h-12 w-12 text-blue-500" />
            </motion.div>
          </div>
        ) : filteredFlights.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
          >
            {filteredFlights.map((flight) => (
              <FlightCard 
                key={flight.id} 
                flight={flight}
                onEdit={(flight) => {
                  setSelectedFlight(flight);
                  setFormData({...flight, date: new Date(flight.date)});
                  setIsAdding(false);
                }}
                onDelete={handleDeleteFlight}
              />
            ))}
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center text-gray-400 mt-12"
          >
            <p className="text-2xl font-semibold">No se encontraron vuelos</p>
            <p className="mt-2">Intenta cambiar los filtros de búsqueda o añade un nuevo vuelo</p>
          </motion.div>
        )}
      </div>
      <motion.div
        className="fixed bottom-0 left-0 right-0"
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 10, repeat: Infinity, repeatType: "reverse", ease: "linear" }}
      >
        <Cloud className="h-16 w-16 text-gray-700 opacity-50" />
      </motion.div>
    </div>
  );
}
