'use client'

import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { Calendar, ChevronLeft, ChevronRight, AlertCircle, Edit2, Save, Plane } from 'lucide-react'
import { collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { BackgroundGradient } from "./ui/background-gradient"
import { HoverEffect } from "./ui/card-hover-effect"
import { TextGenerateEffect } from "./ui/text-generate-effect"
import { AnimatedTooltip } from "./ui/animated-tooltip"
import { Button } from "./ui/moving-border"
import { Input } from "./ui/input"
import { Textarea } from "./ui/textarea"
import { Select } from "./ui/select"

const PREMIUM_ROWS = 6
const ECONOMY_ROWS = 37

const SeatMap = ({ inoperativeSeats, onSeatToggle, selectedSeat }) => {
  const renderSeat = (row, seat, isPremium) => {
    const seatId = `${row}${seat}`
    const isInoperative = inoperativeSeats.some(s => s.id === seatId)
    const isSelected = selectedSeat && selectedSeat.id === seatId
    return (
      <AnimatedTooltip
        items={[
          {
            id: parseInt(row),
            name: `Seat ${seatId}`,
            designation: isPremium ? 'Premium' : 'Economy',
            image: '/placeholder.svg'
          }
        ]}
      >
        <button
          className={`w-6 h-6 m-[1px] text-[9px] font-bold rounded-sm transition-all duration-200 ${
            isSelected
              ? 'bg-yellow-400 text-black ring-2 ring-yellow-500 ring-offset-1'
              : isInoperative 
                ? 'bg-red-500 hover:bg-red-600 text-white' 
                : isPremium 
                  ? 'bg-gray-300 hover:bg-gray-400 text-black' 
                  : 'bg-gray-200 hover:bg-gray-300 text-black'
          }`}
          onClick={() => onSeatToggle(seatId)}
        >
          {seatId}
        </button>
      </AnimatedTooltip>
    )
  }

  const renderPremiumColumn = (row) => (
    <div key={row} className="flex flex-col items-center mr-[1px]">
      <div className="w-6 h-6 flex items-center justify-center text-[9px] font-bold">{row}</div>
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">L</div>}
        {renderSeat(row, 'L', true)}
      </div>
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">J</div>}
        {renderSeat(row, 'J', true)}
      </div>
      <div className="w-6 h-6 m-[1px]" />
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">H</div>}
        {renderSeat(row, 'H', true)}
      </div>
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">E</div>}
        {renderSeat(row, 'E', true)}
      </div>
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">D</div>}
        {renderSeat(row, 'D', true)}
      </div>
      <div className="w-6 h-6 m-[1px]" />
      <div className="w-6 h-[24px]" />
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">C</div>}
        {renderSeat(row, 'C', true)}
      </div>
      <div className="flex items-center">
        {row === 1 && <div className="w-3 h-6 flex items-center justify-center text-[9px] font-bold mr-[1px]">A</div>}
        {renderSeat(row, 'A', true)}
      </div>
    </div>
  )

  const renderEconomyColumn = (row) => (
    <div key={row} className="flex flex-col items-center mr-[1px]">
      <div className="w-6 h-6 flex items-center justify-center text-[9px] font-bold">{row}</div>
      {row === 43 ? (
        <>
          <div className="w-6 h-6 m-[1px]" />
          <div className="w-6 h-6 m-[1px]" />
        </>
      ) : (
        <>
          {renderSeat(row, 'L', false)}
          {renderSeat(row, 'J', false)}
        </>
      )}
      <div className="w-6 h-6 m-[1px]" />
      {row === 7 ? (
        <>
          <div className="w-6 h-6 m-[1px]" />
          <div className="w-6 h-6 m-[1px]" />
        </>
      ) : row >= 40 && row <= 43 ? (
        <>
          {renderSeat(row, 'H', false)}
          <div className="w-6 h-6 m-[1px]" />
        </>
      ) : (
        <>
          {renderSeat(row, 'H', false)}
          {renderSeat(row, 'G', false)}
        </>
      )}
      {row === 7 ? (
        <>
          <div className="w-6 h-6 m-[1px]" />
          <div className="w-6 h-6 m-[1px]" />
        </>
      ) : (
        <>
          {renderSeat(row, 'E', false)}
          {renderSeat(row, 'D', false)}
        </>
      )}
      <div className="w-6 h-6 m-[1px]" />
      {row === 43 ? (
        <>
          <div className="w-6 h-6 m-[1px]" />
          <div className="w-6 h-6 m-[1px]" />
        </>
      ) : (
        <>
          {renderSeat(row, 'C', false)}
          {renderSeat(row, 'A', false)}
        </>
      )}
    </div>
  )

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Airbus A330 Seatmap</h2>
      <div className="flex flex-col md:flex-row">
        <div className="mr-3 mb-4 md:mb-0">
          <h3 className="text-sm font-semibold mb-2">Clase Premium (Filas 1-6)</h3>
          <div className="flex bg-gray-100 p-2 rounded-lg">
            {Array.from({ length: PREMIUM_ROWS }, (_, i) => renderPremiumColumn(i + 1))}
          </div>
        </div>
        <div>
          <h3 className="text-sm font-semibold mb-2">Clase Turista (Filas 7-43)</h3>
          <div className="flex bg-gray-100 p-2 rounded-lg overflow-x-auto">
            {Array.from({ length: ECONOMY_ROWS }, (_, i) => {
              const row = i + PREMIUM_ROWS + 1
              return (
                <React.Fragment key={row}>
                  {renderEconomyColumn(row)}
                  {row === 27 && <div className="w-6" />}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Maintenance({ matricula, matriculas }) {
  const [date, setDate] = useState(new Date())
  const [inoperativeSeats, setInoperativeSeats] = useState({})
  const [selectedClass, setSelectedClass] = useState('Clase 1')
  const [comment, setComment] = useState('')
  const [selectedSeat, setSelectedSeat] = useState(null)

  useEffect(() => {
    const fetchInoperativeSeats = async () => {
      if (matricula) {
        const docRef = doc(db, 'inoperativeSeats', matricula.id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          setInoperativeSeats(docSnap.data())
        } else {
          setInoperativeSeats({})
        }
      }
    }

    fetchInoperativeSeats()
  }, [matricula])

  useEffect(() => {
    const dateString = date.toISOString().split('T')[0]
    if (matricula && !inoperativeSeats[dateString]) {
      setInoperativeSeats(prev => ({ ...prev, [dateString]: [] }))
    }
  }, [date, matricula, inoperativeSeats])

  const handleSeatToggle = async (seatId) => {
    if (!matricula) return

    const dateString = date.toISOString().split('T')[0]
    const updatedSeats = { ...inoperativeSeats }
    if (!updatedSeats[dateString]) {
      updatedSeats[dateString] = []
    }
    const index = updatedSeats[dateString].findIndex(seat => seat.id === seatId)
    if (index > -1) {
      updatedSeats[dateString] = updatedSeats[dateString].filter(seat => seat.id !== seatId)
      setSelectedSeat(null)
    } else {
      updatedSeats[dateString] = [...updatedSeats[dateString], { id: seatId, class: selectedClass, comment }]
      setSelectedSeat({ id: seatId, class: selectedClass, comment })
    }
    setInoperativeSeats(updatedSeats)
    setComment('')

    const docRef = doc(db, 'inoperativeSeats', matricula.id)
    await setDoc(docRef, updatedSeats, { merge: true })
  }

  const currentInoperativeSeats = matricula ? (inoperativeSeats[date.toISOString().split('T')[0]] || []) : []

  const handleSeatSelect = (seat) => {
    setSelectedSeat(seat)
    setSelectedClass(seat.class)
    setComment(seat.comment)
  }

  const handleUpdateSeat = async () => {
    if (selectedSeat && matricula) {
      const dateString = date.toISOString().split('T')[0]
      const updatedSeats = { ...inoperativeSeats }
      const index = updatedSeats[dateString].findIndex(seat => seat.id === selectedSeat.id)
      if (index > -1) {
        updatedSeats[dateString][index] = { ...selectedSeat, class: selectedClass, comment }
      }
      setInoperativeSeats(updatedSeats)
      setSelectedSeat(null)
      setComment('')

      const docRef = doc(db, 'inoperativeSeats', matricula.id)
      await setDoc(docRef, updatedSeats, { merge: true })
    }
  }

  if (!matricula && !matriculas) {
    return <div>Loading...</div>
  }

  if (!matricula) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Mantenimiento de Aviones</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {matriculas.map(mat => (
            <a
              key={mat.id}
              href={`/admin/maintenance/${mat.id}`}
              className="block p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition-shadow"
            >
              <h2 className="text-xl font-semibold mb-2">{mat.matricula}</h2>
              <p className="text-gray-600">{mat.modelo}</p>
            </a>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Mantenimiento de {matricula.matricula} {matricula.modelo}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Calendar className="mr-2" />
            Calendario
          </h2>
          <div className="flex items-center justify-between mb-4">
            <button
              className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
              onClick={() => setDate(prev => new Date(prev.setDate(prev.getDate() - 1)))}
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <span className="text-lg font-medium">{format(date, 'dd MMMM yyyy', { locale: es })}</span>
            <button
              className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
              onClick={() => setDate(prev => new Date(prev.setDate(prev.getDate() + 1)))}
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
          <Input
            type="date"
            value={date.toISOString().split('T')[0]}
            onChange={(e) => setDate(new Date(e.target.value))}
            className="w-full"
          />
        </div>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <AlertCircle className="mr-2" />
            Asientos Inoperativos
          </h2>
          <div className="mb-4 max-h-60 overflow-y-auto">
            {currentInoperativeSeats.length > 0 ? (
              <ul className="space-y-2">
                {currentInoperativeSeats.map(seat => (
                  <li 
                    key={seat.id} 
                    className={`flex items-center justify-between p-3 rounded-md cursor-pointer transition-colors duration-200 ${
                      selectedSeat && selectedSeat.id === seat.id ? 'bg-gray-100 border-l-4 border-gray-500' : 'hover:bg-gray-50'
                    }`}
                    onClick={() => handleSeatSelect(seat)}
                  >
                    <div className="flex items-center">
                      <span className="font-semibold text-lg mr-2">{seat.id}</span>
                      <span className="text-sm text-gray-600 bg-gray-200 px-2 py-1 rounded">{seat.class}</span>
                    </div>
                    <span className="text-sm italic truncate max-w-[150px] text-gray-500">{seat.comment}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-center py-4">No hay asientos inoperativos</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Clase</label>
            <Select
              value={selectedClass}
              onValueChange={(value) => setSelectedClass(value)}
            >
              <Select.Option value="Clase 1">Clase 1</Select.Option>
              <Select.Option value="Clase 2">Clase 2</Select.Option>
              <Select.Option value="Clase 3">Clase 3</Select.Option>
              <Select.Option value="Clase 4">Clase 4</Select.Option>
            </Select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Comentario</label>
            <Textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Añadir comentario"
              rows={3}
            />
          </div>
          <button
            className={`w-full py-2 px-4 rounded-md transition-colors ${
              selectedSeat
                ? 'bg-gray-800 text-white hover:bg-gray-700'
                : 'bg-gray-200 text-gray-400 cursor-not-allowed'
            }`}
            onClick={handleUpdateSeat}
            disabled={!selectedSeat}
          >
            {selectedSeat ? (
              <>
                <Save className="inline-block mr-2" />
                Actualizar Asiento
              </>
            ) : (
              <>
                <Edit2 className="inline-block mr-2" />
                Seleccione un asiento
              </>
            )}
          </button>
        </div>
      </div>
      <SeatMap 
        inoperativeSeats={currentInoperativeSeats}
        onSeatToggle={handleSeatToggle}
        selectedSeat={selectedSeat}
      />
    </div>
  )
}