'use client'

import React, { useState, useEffect, useMemo } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { FiEdit, FiTrash2, FiSearch, FiChevronUp, FiChevronDown, FiPlus, FiMapPin, FiPhone, FiMail, FiBriefcase, FiGlobe, FiSun, FiMoon } from 'react-icons/fi'

const locationImages = {
  EZE: "https://americasquarterly.org/wp-content/uploads/2016/10/Argentina_Top.jpg",
  LAX: "https://www.civitatis.com/f/estados-unidos/los-angeles/los-angeles-m.jpg",
  SFO: "https://wallpapers.com/images/featured/horizonte-de-san-francisco-1ihpn12wdtk5xa9h.jpg",
  SCL: "https://www.blogdelfotografo.com/wp-content/uploads/2023/01/vista-panoramica-santiago-chile.webp",
  JFK: "https://www.vacacionesnuevayork.com/wp-content/uploads/2019/02/new-york.jpg",
  BOS: "https://content.r9cdn.net/rimg/dimg/8d/d4/5837febe-city-25588-16b90080f1e.jpg?width=1366&height=768&xhint=2723&yhint=1685&crop=true",
  BCN: "https://www.civitatis.com/blog/wp-content/uploads/2024/02/shutterstock_1891578742-1920x867.jpg",
  MIA: "https://www.viajesyfotografia.com/wp-content/uploads/2014/09/art-deco-district-miami-beach.jpg"
}

const ContactCard = ({ contact, onEdit, onDelete }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transform transition-all duration-300 ease-in-out hover:shadow-xl"
    >
      <div className="relative h-40">
        <img
          src={locationImages[contact.location]}
          alt={contact.location}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
        <div className="absolute bottom-4 left-4 flex items-center space-x-3">
          <div className="w-16 h-16 bg-white dark:bg-gray-700 rounded-full flex items-center justify-center text-2xl font-bold text-gray-700 dark:text-gray-200 border-4 border-blue-500">
            {contact.name.charAt(0)}
          </div>
          <div className="text-white">
            <h3 className="text-xl font-semibold">{contact.name}</h3>
            <p className="text-sm opacity-75">{contact.department}</p>
          </div>
        </div>
        <div className="absolute top-2 right-2 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-2 py-1 rounded-full text-xs font-semibold flex items-center">
          <FiMapPin className="mr-1" />
          {contact.location}
        </div>
      </div>
      <div className="p-4 space-y-2">
        <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center"><FiMail className="mr-2" />{contact.email}</p>
        <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center"><FiPhone className="mr-2" />{contact.phone}</p>
        <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center"><FiBriefcase className="mr-2" />Compañía: {contact.cia}</p>
      </div>
      <div className="flex justify-end space-x-2 p-4 bg-gray-100 dark:bg-gray-700">
        <button
          onClick={() => onEdit(contact)}
          className="px-3 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 flex items-center"
          aria-label={`Editar ${contact.name}`}
        >
          <FiEdit className="mr-1" /> Editar
        </button>
        <button
          onClick={() => onDelete(contact.id)}
          className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors duration-300 flex items-center"
          aria-label={`Eliminar ${contact.name}`}
        >
          <FiTrash2 className="mr-1" /> Eliminar
        </button>
      </div>
    </motion.div>
  )
}

const CompanyCard = ({ company, onEdit, onDelete }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transform transition-all duration-300 ease-in-out hover:shadow-xl"
    >
      <div className="flex justify-center mb-4">
        <div className="w-20 h-20 bg-blue-500 rounded-full flex items-center justify-center text-2xl font-bold text-white">
          {company.abbreviation}
        </div>
      </div>
      <h3 className="text-xl font-semibold text-center mb-2 text-gray-800 dark:text-gray-200">{company.name}</h3>
      <p className="text-gray-600 dark:text-gray-400 text-center mb-4">{company.abbreviation}</p>
      <div className="flex justify-center space-x-2">
        <button
          onClick={() => onEdit(company)}
          className="px-3 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 flex items-center"
          aria-label={`Editar ${company.name}`}
        >
          <FiEdit className="mr-1" /> Editar
        </button>
        <button
          onClick={() => onDelete(company.id)}
          className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors duration-300 flex items-center"
          aria-label={`Eliminar ${company.name}`}
        >
          <FiTrash2 className="mr-1" /> Eliminar
        </button>
      </div>
    </motion.div>
  )
}

export default function ContactManagement() {
  const [contacts, setContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [newContact, setNewContact] = useState({ name: '', email: '', phone: '', department: '', location: '', cia: '' })
  const [editingContact, setEditingContact] = useState(null)
  const [newCompany, setNewCompany] = useState({ name: '', abbreviation: '' })
  const [editingCompany, setEditingCompany] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterDepartment, setFilterDepartment] = useState('')
  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [showContactDialog, setShowContactDialog] = useState(false)
  const [showCompanyDialog, setShowCompanyDialog] = useState(false)
  const [activeTab, setActiveTab] = useState('contacts')
  const [viewMode, setViewMode] = useState('grid')
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    fetchContacts()
    fetchCompanies()
  }, [])

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode)
  }, [isDarkMode])

  const fetchContacts = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const querySnapshot = await getDocs(collection(db, 'contacts'))
      const fetchedContacts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      setContacts(fetchedContacts)
    } catch (err) {
      console.error('Error fetching contacts:', err)
      setError('Error al obtener contactos. Por favor, inténtalo de nuevo.')
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCompanies = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'companies'))
      const fetchedCompanies = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      setCompanies(fetchedCompanies)
    } catch (err) {
      console.error('Error fetching companies:', err)
      setError('Error al obtener compañías. Por favor, inténtalo de nuevo.')
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (editingContact) {
      setEditingContact({ ...editingContact, [name]: value })
    } else {
      setNewContact({ ...newContact, [name]: value })
    }
  }

  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target
    if (editingCompany) {
      setEditingCompany({ ...editingCompany, [name]: value })
    } else {
      setNewCompany({ ...newCompany, [name]: value })
    }
  }

  const handleContactSubmit = async (e) => {
    e.preventDefault()
    if (editingContact) {
      await updateContact(editingContact)
    } else {
      await addContact(newContact)
    }
    setShowContactDialog(false)
  }

  const handleCompanySubmit = async (e) => {
    e.preventDefault()
    if (editingCompany) {
      await updateCompany(editingCompany)
    } else {
      await addCompany(newCompany)
    }
    setShowCompanyDialog(false)
  }

  const addContact = async (contact) => {
    try {
      await addDoc(collection(db, 'contacts'), contact)
      setNewContact({ name: '', email: '', phone: '', department: '', location: '', cia: '' })
      fetchContacts()
    } catch (err) {
      console.error('Error adding contact:', err)
      setError('Error al añadir contacto. Por favor, inténtalo de nuevo.')
    }
  }

  const updateContact = async (contact) => {
    try {
      const contactRef = doc(db, 'contacts', contact.id)
      await updateDoc(contactRef, contact)
      setEditingContact(null)
      fetchContacts()
    } catch (err) {
      console.error('Error updating contact:', err)
      setError('Error al actualizar contacto. Por favor, inténtalo de nuevo.')
    }
  }

  const deleteContact = async (id) => {
    try {
      await deleteDoc(doc(db, 'contacts', id))
      fetchContacts()
    } catch (err) {
      console.error('Error deleting contact:', err)
      setError('Error al eliminar contacto. Por favor, inténtalo de nuevo.')
    }
  }

  const addCompany = async (company) => {
    try {
      await addDoc(collection(db, 'companies'), company)
      setNewCompany({ name: '', abbreviation: '' })
      fetchCompanies()
    } catch (err) {
      console.error('Error adding company:', err)
      setError('Error al añadir compañía. Por favor, inténtalo de nuevo.')
    }
  }

  const updateCompany = async (company) => {
    try {
      const companyRef = doc(db, 'companies', company.id)
      await updateDoc(companyRef, company)
      setEditingCompany(null)
      fetchCompanies()
    } catch (err) {
      console.error('Error updating company:', err)
      setError('Error al actualizar compañía. Por favor, inténtalo de nuevo.')
    }
  }

  const deleteCompany = async (id) => {
    try {
      await deleteDoc(doc(db, 'companies', id))
      fetchCompanies()
    } catch (err) {
      console.error('Error deleting company:', err)
      setError('Error al eliminar compañía. Por favor, inténtalo de nuevo.')
    }
  }

  const filteredAndSortedContacts = useMemo(() => {
    return contacts
      .filter(contact =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.phone.includes(searchTerm)
      )
      .filter(contact => filterDepartment ? contact.department === filterDepartment : true)
      .sort((a, b) => {
        if (sortDirection === 'asc') {
          return a[sortBy] < b[sortBy] ? -1 : 1
        } else {
          return a[sortBy] > b[sortBy] ? -1 : 1
        }
      })
  }, [contacts, searchTerm, filterDepartment, sortBy, sortDirection])

  const departments = useMemo(() => {
    return [...new Set(contacts.map(contact => contact.department))]
  }, [contacts])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
        <div className="text-3xl font-bold text-gray-800 dark:text-gray-200 animate-pulse">Cargando datos...</div>
      </div>
    )
  }

  return (
    <div className={`min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-300 ${isDarkMode ? 'dark' : ''}`}>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200">Gestión de Contactos y Compañías</h1>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setIsDarkMode(!isDarkMode)}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
              aria-label="Cambiar tema"
            >
              {isDarkMode ? <FiSun className="w-5 h-5" /> : <FiMoon className="w-5 h-5" />}
            </button>
            <button
              onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
              aria-label="Cambiar vista"
            >
              <FiGlobe className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
          <div className="flex space-x-4 mb-6">
            <button
              onClick={() => setActiveTab('contacts')}
              className={`px-4 py-2 rounded-full font-semibold transition-colors duration-300 ${
                activeTab === 'contacts'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
              }`}
            >
              Contactos
            </button>
            <button
              onClick={() => setActiveTab('companies')}
              className={`px-4 py-2 rounded-full font-semibold transition-colors duration-300 ${
                activeTab === 'companies'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'
              }`}
            >
              Compañías
            </button>
          </div>

          {activeTab === 'contacts' && (
            <>
              <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-6 space-y-4 md:space-y-0">
                <div className="relative flex-grow md:mr-4">
                  <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Buscar por nombre, email o teléfono..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                  />
                </div>
                <div className="flex space-x-2">
                  <select
                    value={filterDepartment}
                    onChange={(e) => setFilterDepartment(e.target.value)}
                    className="px-4 py-2 rounded-full border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                  >
                    <option value="">Todos los departamentos</option>
                    {departments.map(dept => (
                      <option key={dept} value={dept}>{dept}</option>
                    ))}
                  </select>
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="px-4 py-2 rounded-full border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                  >
                    <option value="name">Nombre</option>
                    <option value="department">Departamento</option>
                    <option value="location">Ubicación</option>
                  </select>
                  <button
                    onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
                    className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
                  >
                    {sortDirection === 'asc' ? <FiChevronUp className="w-5 h-5" /> : <FiChevronDown className="w-5 h-5" />}
                  </button>
                </div>
              </div>

              <div className={`grid gap-6 ${viewMode === 'grid' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'}`}>
                <AnimatePresence>
                  {filteredAndSortedContacts.map((contact) => (
                    <ContactCard
                      key={contact.id}
                      contact={contact}
                      onEdit={() => {
                        setEditingContact(contact)
                        setShowContactDialog(true)
                      }}
                      onDelete={deleteContact}
                    />
                  ))}
                </AnimatePresence>
              </div>
            </>
          )}

          {activeTab === 'companies' && (
            <div className={`grid gap-6 ${viewMode === 'grid' ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'}`}>
              {companies.map((company) => (
                <CompanyCard
                  key={company.id}
                  company={company}
                  onEdit={() => {
                    setEditingCompany(company)
                    setShowCompanyDialog(true)
                  }}
                  onDelete={deleteCompany}
                />
              ))}
            </div>
          )}
        </div>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            if (activeTab === 'contacts') {
              setEditingContact(null)
              setShowContactDialog(true)
            } else {
              setEditingCompany(null)
              setShowCompanyDialog(true)
            }
          }}
          className="fixed bottom-8 right-8 bg-blue-500 text-white rounded-full p-4 shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300"
        >
          <FiPlus className="text-2xl" />
        </motion.button>

        {/* Contact Dialog */}
        {showContactDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md"
            >
              <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">{editingContact ? 'Editar Contacto' : 'Nuevo Contacto'}</h2>
              <form onSubmit={handleContactSubmit}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="name">
                      Nombre
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={editingContact ? editingContact.name : newContact.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={editingContact ? editingContact.email : newContact.email}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="phone">
                      Teléfono
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={editingContact ? editingContact.phone : newContact.phone}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="department">
                      Departamento
                    </label>
                    <input
                      type="text"
                      id="department"
                      name="department"
                      value={editingContact ? editingContact.department : newContact.department}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="location">
                      Ubicación
                    </label>
                    <select
                      id="location"
                      name="location"
                      value={editingContact ? editingContact.location : newContact.location}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    >
                      <option value="">Seleccionar ubicación</option>
                      {Object.keys(locationImages).map((loc) => (
                        <option key={loc} value={loc}>{loc}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="cia">
                      Compañía
                    </label>
                    <select
                      id="cia"
                      name="cia"
                      value={editingContact ? editingContact.cia : newContact.cia}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    >
                      <option value="">Seleccionar compañía</option>
                      {companies.map((company) => (
                        <option key={company.id} value={company.name}>{company.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowContactDialog(false)}
                    className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    {editingContact ? 'Actualizar' : 'Añadir'}
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        )}

        {/* Company Dialog */}
        {showCompanyDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md"
            >
              <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">{editingCompany ? 'Editar Compañía' : 'Nueva Compañía'}</h2>
              <form onSubmit={handleCompanySubmit}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="companyName">
                      Nombre
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="name"
                      value={editingCompany ? editingCompany.name : newCompany.name}
                      onChange={handleCompanyInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="companyAbbreviation">
                      Siglas
                    </label>
                    <input
                      type="text"
                      id="companyAbbreviation"
                      name="abbreviation"
                      value={editingCompany ? editingCompany.abbreviation : newCompany.abbreviation}
                      onChange={handleCompanyInputChange}
                      className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                      required
                    />
                  </div>
                </div>
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowCompanyDialog(false)}
                    className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    {editingCompany ? 'Actualizar' : 'Añadir'}
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  )
}