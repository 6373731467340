'use client'

import React, { useState, useEffect, useCallback } from 'react'
import { UserCircle, CalendarDays, CheckCircle, Edit, Trash, Clock, X, Search, Bell, Settings, ChevronLeft, ChevronRight, Plus, PlusCircle, Calendar, ChevronDown, ChevronUp } from 'lucide-react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc, getDocs, updateDoc, doc, deleteDoc, query, where, Timestamp, orderBy, limit, onSnapshot } from 'firebase/firestore'
import { motion, AnimatePresence } from 'framer-motion'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const firebaseConfig = {
  apiKey: "AIzaSyB-uKNDLG68j7YnaWW_YGXVBt7SNeqpuvU",
  authDomain: "dashboard-4d770.firebaseapp.com",
  projectId: "dashboard-4d770",
  storageBucket: "dashboard-4d770.appspot.com",
  messagingSenderId: "588845617740",
  appId: "1:588845617740:web:YOUR_WEB_APP_ID_HERE"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const CollapsibleText = ({ text, maxLength = 50 }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const displayText = isExpanded ? text : text.slice(0, maxLength)

  return (
    <div className="relative">
      <p className="text-xs whitespace-normal break-words">
        {displayText}
        {text.length > maxLength && !isExpanded && '...'}
      </p>
      {text.length > maxLength && (
        <button
          onClick={toggleExpand}
          className="text-blue-500 hover:text-blue-700 text-xs mt-1 flex items-center"
        >
          {isExpanded ? (
            <>
              <ChevronUp size={14} className="mr-1" />
              Ver menos
            </>
          ) : (
            <>
              <ChevronDown size={14} className="mr-1" />
              Ver más
            </>
          )}
        </button>
      )}
    </div>
  )
}

export default function Check030() {
  const [records, setRecords] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingRecord, setEditingRecord] = useState(null)
  const [formData, setFormData] = useState({
    pnr: '',
    flightDate: new Date(),
    flightNumber: '',
    pendingAmount: '',
    pendingReason: ''
  })
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [searchTerm, setSearchTerm] = useState('')
  const [notificationCount, setNotificationCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [expandedRecord, setExpandedRecord] = useState(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [currentUser] = useState({ name: 'John Doe', image: `https://randomuser.me/api/portraits/${Math.random() < 0.5 ? 'men' : 'women'}/${Math.floor(Math.random() * 100)}.jpg` })

  const fetchRecords = useCallback(() => {
    setIsLoading(true)
    setError(null)
    try {
      const startOfDay = new Date(selectedDate)
      startOfDay.setHours(0, 0, 0, 0)
      const endOfDay = new Date(selectedDate)
      endOfDay.setHours(23, 59, 59, 999)

      const q = query(
        collection(db, "check030"),
        where("flightDate", ">=", Timestamp.fromDate(startOfDay)),
        where("flightDate", "<=", Timestamp.fromDate(endOfDay)),
        orderBy("flightDate", "desc")
      )

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedRecords = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        setRecords(fetchedRecords)
        setNotificationCount(fetchedRecords.length)
        setIsLoading(false)
      }, (error) => {
        console.error("Error fetching records:", error)
        setError("Failed to fetch records. Please try again.")
        setIsLoading(false)
      })

      return unsubscribe
    } catch (error) {
      console.error("Error setting up record listener:", error)
      setError("Failed to set up record listener. Please try again.")
      setIsLoading(false)
    }
  }, [selectedDate])

  useEffect(() => {
    const unsubscribe = fetchRecords()
    return () => unsubscribe && unsubscribe()
  }, [fetchRecords])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleDateChange = (date) => {
    setFormData(prev => ({ ...prev, flightDate: date }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const newRecord = {
        ...formData,
        flightDate: Timestamp.fromDate(formData.flightDate),
        pendingAmount: parseFloat(formData.pendingAmount),
        createdBy: currentUser.name,
        timestamp: Timestamp.fromDate(new Date())
      }
      if (editingRecord) {
        await updateDoc(doc(db, "check030", editingRecord.id), newRecord)
      } else {
        await addDoc(collection(db, "check030"), newRecord)
      }
      setIsModalOpen(false)
      setEditingRecord(null)
      setFormData({
        pnr: '',
        flightDate: new Date(),
        flightNumber: '',
        pendingAmount: '',
        pendingReason: ''
      })
    } catch (error) {
      console.error("Error submitting record:", error)
      setError("Failed to submit record. Please try again.")
    }
  }

  const handleEdit = (record) => {
    setEditingRecord(record)
    setFormData({
      pnr: record.pnr,
      flightDate: record.flightDate.toDate(),
      flightNumber: record.flightNumber,
      pendingAmount: record.pendingAmount.toString(),
      pendingReason: record.pendingReason
    })
    setIsModalOpen(true)
  }

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "check030", id))
      if (selectedRecord && selectedRecord.id === id) {
        setSelectedRecord(null)
      }
    } catch (error) {
      console.error("Error deleting record:", error)
      setError("Failed to delete record. Please try again.")
    }
  }

  const changeDate = (increment) => {
    const newDate = new Date(selectedDate)
    newDate.setDate(newDate.getDate() + increment)
    setSelectedDate(newDate)
  }

  const filteredRecords = records.filter(record =>
    (record.pnr?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (record.flightNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    (record.pendingReason?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
  )

  const toggleExpandRecord = (recordId) => {
    setExpandedRecord(expandedRecord === recordId ? null : recordId)
  }

  if (isLoading) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-sm font-semibold text-gray-600 animate-pulse">Loading...</div>
    </div>
  }

  if (error) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-sm font-medium text-red-600">{error}</div>
    </div>
  }

  return (
    <div className="min-h-screen bg-gray-0 p-4 font-sans text-xs">
      <div className="max-w-7xl mx-auto">
        <header className="bg-cover bg-center shadow-md rounded-lg p-4 mb-6 relative overflow-hidden" style={{backgroundImage: `url(https://images4.alphacoders.com/129/1299844.jpg)`}}>
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="flex justify-between items-center relative z-10">
            <div className="flex items-center space-x-4">
              <img src={currentUser.image} alt="User" className="w-10 h-10 rounded-full shadow-sm" />
              <div>
                <h1 className="text-lg font-semibold text-white">Check 030 Dashboard</h1>
                <p className="text-xs text-gray-300">{currentUser.name} - Call Center Agent</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="p-2 text-white hover:text-gray-200 transition-colors duration-200"
              >
                <Bell size={18} />
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="p-2 text-white hover:text-gray-200 transition-colors duration-200"
              >
                <Settings size={18} />
              </motion.button>
            </div>
          </div>
        </header>

        <div className="flex space-x-6">
          <aside className="w-1/4">
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-sm font-semibold text-gray-700">Date Selection</h2>
              </div>
              <div className="flex items-center justify-between mb-2">
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => changeDate(-1)} 
                  className="p-1 rounded-full hover:bg-gray-100 text-gray-600"
                >
                  <ChevronLeft size={16} />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                  className="text-xs font-medium text-gray-600 hover:text-gray-800"
                >
                  {selectedDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                </motion.button>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => changeDate(1)} 
                  className="p-1 rounded-full hover:bg-gray-100 text-gray-600"
                >
                  <ChevronRight size={16} />
                </motion.button>
              </div>
              {isCalendarOpen && (
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date)
                    setIsCalendarOpen(false)
                  }}
                  inline
                  className="w-full"
                />
              )}
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              <h2 className="text-sm font-semibold text-gray-700 mb-4">Quick Stats</h2>
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-500">Total Records</span>
                  <span className="font-medium text-gray-700">{records.length}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-xs text-gray-500">High Amount Records</span>
                  <span className="font-medium text-yellow-600">{records.filter(r => r.pendingAmount > 300).length}</span>
                </div>
              </div>
            </div>
          </aside>

          <main className="flex-1">
            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-sm font-semibold text-gray-700">Check 030 Records</h2>
                <div className="flex space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsModalOpen(true)}
                    className="bg-gray-600 text-white px-3 py-1 rounded-md text-xs hover:bg-gray-700 transition-colors duration-200 shadow-sm"
                  >
                    New Record
                  </motion.button>
                </div>
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search records..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 border rounded-md text-xs focus: outline-none focus:ring-2 focus:ring-gray-500"
                />
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PNR</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Flight Date</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Flight Number</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pending Amount</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredRecords.map((record) => (
                      <React.Fragment key={record.id}>
                        <motion.tr 
                          className="hover:bg-gray-50 transition-colors duration-150 cursor-pointer"
                          onClick={() => toggleExpandRecord(record.id)}
                          whileHover={{ backgroundColor: "#F3F4F6" }}
                        >
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{record.pnr}</td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{record.flightDate.toDate().toLocaleDateString()}</td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs">{record.flightNumber}</td>
                          <td className={`px-4 py-3 whitespace-nowrap text-xs font-medium ${record.pendingAmount > 300 ? 'text-red-600' : 'text-gray-900'}`}>
                            {record.pendingAmount.toFixed(2)} €
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs font-medium">
                            <motion.button 
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={(e) => { e.stopPropagation(); handleEdit(record); }} 
                              className="text-gray-600 hover:text-gray-900 mr-2 transition-colors duration-200"
                            >
                              <Edit size={14} />
                            </motion.button>
                            <motion.button 
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={(e) => { e.stopPropagation(); handleDelete(record.id); }} 
                              className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
                            >
                              <Trash size={14} />
                            </motion.button>
                          </td>
                        </motion.tr>
                        <AnimatePresence>
                          {expandedRecord === record.id && (
                            <motion.tr
                              initial={{ opacity: 0, height: 0 }}
                              animate={{ opacity: 1, height: 'auto' }}
                              exit={{ opacity: 0, height: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <td colSpan={5} className="px-4 py-3">
                                <div className="bg-gray-50 p-4 rounded-md overflow-hidden">
                                  <h4 className="font-semibold mb-2 text-xs">Record Details</h4>
                                  <p className="mb-1 text-xs"><strong>PNR:</strong> {record.pnr}</p>
                                  <p className="mb-1 text-xs"><strong>Flight Date:</strong> {record.flightDate.toDate().toLocaleDateString()}</p>
                                  <p className="mb-1 text-xs"><strong>Flight Number:</strong> {record.flightNumber}</p>
                                  <p className="mb-1 text-xs"><strong>Pending Amount:</strong> {record.pendingAmount.toFixed(2)} €</p>
                                  <p className="mb-1 text-xs"><strong>Pending Reason:</strong></p>
                                  <div className="max-w-full overflow-hidden">
                                    <CollapsibleText text={record.pendingReason} maxLength={50} />
                                  </div>
                                  <p className="mb-1 text-xs"><strong>Created At:</strong> {record.timestamp.toDate().toLocaleString()}</p>
                                  <p className="mb-1 text-xs"><strong>Created By:</strong> {record.createdBy}</p>
                                </div>
                              </td>
                            </motion.tr>
                          )}
                        </AnimatePresence>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md"
            >
              <h2 className="text-sm font-semibold mb-4 text-gray-800">{editingRecord ? 'Edit Record' : 'New Record'}</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="pnr" className="block text-xs font-medium text-gray-700">PNR</label>
                  <input
                    type="text"
                    id="pnr"
                    name="pnr"
                    value={formData.pnr}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="flightDate" className="block text-xs font-medium text-gray-700">Flight Date</label>
                  <DatePicker
                    selected={formData.flightDate}
                    onChange={handleDateChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="flightNumber" className="block text-xs font-medium text-gray-700">Flight Number</label>
                  <input
                    type="text"
                    id="flightNumber"
                    name="flightNumber"
                    value={formData.flightNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="pendingAmount" className="block text-xs font-medium text-gray-700">Pending Amount</label>
                  <input
                    type="number"
                    id="pendingAmount"
                    name="pendingAmount"
                    value={formData.pendingAmount}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="pendingReason" className="block text-xs font-medium text-gray-700">Pending Reason</label>
                  <textarea
                    id="pendingReason"
                    name="pendingReason"
                    value={formData.pendingReason}
                    onChange={handleInputChange}
                    rows={3}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-xs focus:outline-none focus:ring-2 focus:ring-gray-500"
                    required
                  ></textarea>
                </div>
                <div className="flex justify-end space-x-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="px-3 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-200"
                  >
                    Cancel
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    className="px-3 py-1 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                  >
                    {editingRecord ? 'Update' : 'Create'}
                  </motion.button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}