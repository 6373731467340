import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown } from 'lucide-react'

const Select = ({ value, onValueChange, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleToggle = () => setIsOpen(!isOpen)

  const handleSelect = (newValue) => {
    onValueChange(newValue)
    setIsOpen(false)
  }

  return (
    <div className="relative" ref={selectRef}>
      <SelectTrigger onClick={handleToggle}>
        <SelectValue>{value || 'Select an option'}</SelectValue>
      </SelectTrigger>
      {isOpen && (
        <SelectContent>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onSelect: () => handleSelect(child.props.value),
            })
          )}
        </SelectContent>
      )}
    </div>
  )
}

const SelectTrigger = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="flex items-center justify-between w-full px-4 py-2 text-sm bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    {children}
    <ChevronDown className="w-4 h-4 ml-2" />
  </button>
)

const SelectValue = ({ children }) => <span>{children}</span>

const SelectContent = ({ children }) => (
  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-700 rounded-md shadow-lg">
    {children}
  </div>
)

const SelectItem = ({ children, value, onSelect }) => (
  <div
    className="px-4 py-2 text-sm text-gray-300 cursor-pointer hover:bg-gray-700"
    onClick={() => onSelect(value)}
  >
    {children}
  </div>
)

export { Select, SelectItem, SelectTrigger, SelectValue, SelectContent }