import React, { useState } from 'react';
import axios from 'axios';

const FlightSearch = () => {
  const [flightNumber, setFlightNumber] = useState('');  // Estado para guardar el número de vuelo
  const [flightDate, setFlightDate] = useState('');      // Estado para guardar la fecha de búsqueda
  const [flightInfo, setFlightInfo] = useState(null);    // Estado para la información del vuelo
  const [loading, setLoading] = useState(false);         // Estado para mostrar la carga
  const [error, setError] = useState(null);              // Estado para los errores

  // Función para manejar la búsqueda del vuelo
  const handleSearch = async (e) => {
    e.preventDefault();  // Prevenir la recarga de la página
    setLoading(true);
    setError(null);

    try {
      // Realizamos la solicitud a la API de Flightradar24 usando el endpoint relativo
      const response = await axios.get(
        `/common/v1/flight/list.json?query=${flightNumber}&fetchBy=flight&date=${flightDate}`
      );

      // Verificamos si la respuesta es válida y contiene datos
      if (response.data && response.data.result && response.data.result.response.data.length > 0) {
        setFlightInfo(response.data.result.response.data[0]);  // Guardamos la información del primer vuelo
      } else {
        setError('No se encontró información para el vuelo proporcionado.');
      }
    } catch (err) {
      console.error(err);
      setError('No se pudo obtener la información del vuelo. Verifica el número de vuelo y la fecha.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Búsqueda de información de vuelo</h1>
      <form onSubmit={handleSearch}>
        <div>
          <label>
            Introduce el número de vuelo:
            <input 
              type="text" 
              value={flightNumber} 
              onChange={(e) => setFlightNumber(e.target.value)} 
              placeholder="Ejemplo: AA123"
              required 
            />
          </label>
        </div>
        <div>
          <label>
            Introduce la fecha del vuelo (YYYY-MM-DD):
            <input 
              type="date" 
              value={flightDate} 
              onChange={(e) => setFlightDate(e.target.value)} 
              required 
            />
          </label>
        </div>
        <button type="submit">Buscar</button>
      </form>

      {loading && <p>Cargando datos del vuelo...</p>}

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {flightInfo && (
        <div>
          <h2>Información del vuelo {flightNumber}</h2>
          <h3>Aerolínea y Vuelo</h3>
          <p>Aerolínea: {flightInfo.airline.name}</p>
          <p>Tipo de aeronave: {flightInfo.aircraft.model.text}</p>
          <p>Número de vuelo: {flightInfo.identification.number.default}</p>

          <h3>Detalles del Aeropuerto</h3>
          <p>Origen: {flightInfo.airport.origin.name} ({flightInfo.airport.origin.code.iata})</p>
          <p>Destino: {flightInfo.airport.destination.name} ({flightInfo.airport.destination.code.iata})</p>

          <h3>Tiempo del Vuelo</h3>
          <p>Hora de salida (programada): {new Date(flightInfo.time.scheduled.departure * 1000).toLocaleString()}</p>
          <p>Hora de llegada (programada): {new Date(flightInfo.time.scheduled.arrival * 1000).toLocaleString()}</p>

          <h3>Estado del Vuelo</h3>
          <p>Estado actual: {flightInfo.status.text}</p>
          <p>Altitud: {flightInfo.trail && flightInfo.trail[0] ? flightInfo.trail[0].alt : 'N/A'} pies</p>
          <p>Velocidad: {flightInfo.trail && flightInfo.trail[0] ? flightInfo.trail[0].spd : 'N/A'} km/h</p>

          <h3>Posición Actual</h3>
          {flightInfo.trail && flightInfo.trail[0] ? (
            <>
              <p>Latitud: {flightInfo.trail[0].lat}</p>
              <p>Longitud: {flightInfo.trail[0].lng}</p>
            </>
          ) : (
            <p>Posición no disponible.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FlightSearch;
