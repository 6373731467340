'use client'

import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Home, Users, Plane, Wrench, Phone,
  User, Calendar, BookOpen, Settings,
  ChevronsLeft, ChevronsRight, Filter, Table,
  Bell, Search, AlertTriangle, LogOut,
  ChevronDown, Menu, MessageSquare,
  UserCircle, Settings as SettingsIcon, HelpCircle,
  Moon, Sun, Globe, CheckCircle, HelpCircle as QuestionMark
} from 'lucide-react';
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebase';
import { useNavigate, Link, Routes, Route, useLocation } from 'react-router-dom';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, startOfWeek, endOfWeek, addWeeks, startOfMonth, endOfMonth, isValid, isSameWeek, isSameMonth } from 'date-fns';
import { collection, query, where, onSnapshot, Timestamp } from 'firebase/firestore';
import UserManagement from './UserManagement';
import FlightsScreen from './FlightsScreen';
import PassengersScreen from './PassengersScreen';
import DataUploadScreen from './DataUploadScreen';
import ContactManagement from './ContactManagement';
import Manuals from './Manuals';
import RosterSystem from './RosterSystem';
import CallCenter from './CallCenter';
import Matriculas from './Matriculas';
import Maintenance from './Maintenance';
import Check030 from './check030';
import Qanda from './Qanda';

const navigation = [
  { name: 'Dashboard', href: '/admin', icon: Home },
  { name: 'Passengers', href: '/admin/passengers', icon: Users },
  { name: 'Flights', href: '/admin/flights', icon: Plane },
  { name: 'Manuals', href: '/admin/manuals', icon: BookOpen },
  { name: 'Disruptions', href: '/admin/disruptions', icon: AlertTriangle },
  { 
    name: 'Maintenance', 
    href: '/admin/maintenance', 
    icon: Wrench,
    submenu: [] // This will be populated with matriculas data
  },
  { 
    name: 'Call Center', 
    href: '/admin/call-center', 
    icon: Phone,
    submenu: [
      { name: 'Check 030', href: '/admin/call-center/check-030', icon: CheckCircle },
      { name: 'Q & A', href: '/admin/call-center/q-and-a', icon: QuestionMark }
    ]
  },
  { name: 'Crew', href: '/admin/crew', icon: User },
  { name: 'Roster', href: '/admin/roster', icon: Calendar },
  { name: 'Contacts', href: '/admin/contacts', icon: BookOpen },
  { 
    name: 'Settings', 
    href: '#', 
    icon: Settings,
    submenu: [
      { name: 'Users', href: '/admin/settings/users' },
      { name: 'Matrículas', href: '/admin/settings/matriculas' },
      { name: 'Vuelos', href: '/admin/settings/vuelos' },
      { name: 'Data', href: '/admin/settings/data', icon: Table }
    ]
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Button = ({ children, ...props }) => (
  <button {...props} className={`px-4 py-2 text-sm font-medium rounded-md transition-all duration-300 ease-in-out ${props.className}`}>
    {children}
  </button>
);

const Card = ({ children, ...props }) => (
  <div {...props} className={`bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg shadow-lg overflow-hidden ${props.className}`}>
    {children}
  </div>
);

const CardContent = ({ children, ...props }) => (
  <div {...props} className={`p-6 ${props.className}`}>
    {children}
  </div>
);

const Select = ({ children, ...props }) => (
  <select {...props} className={`px-4 py-2 text-sm rounded-md border border-gray-700 bg-gray-800 text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${props.className}`}>
    {children}
  </select>
);

const calculateDelay = (flight) => {
  const parseTime = (timeString) => {
    if (!timeString) return null;
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const scheduledDeparture = parseTime(flight.std);
  const actualDeparture = parseTime(flight.realTimeDeparture);
  const scheduledArrival = parseTime(flight.sta);
  const actualArrival = parseTime(flight.realTimeArrival);

  if (scheduledDeparture === null || actualDeparture === null || scheduledArrival === null || actualArrival === null) {
    return { delayed: false, departureDelay: 0, arrivalDelay: 0 };
  }

  const departureDelayInMinutes = actualDeparture - scheduledDeparture;
  const arrivalDelayInMinutes = actualArrival - scheduledArrival;
  const delayed = departureDelayInMinutes > 15 || arrivalDelayInMinutes > 15;

  return {
    delayed,
    departureDelay: Math.max(0, departureDelayInMinutes),
    arrivalDelay: Math.max(0, arrivalDelayInMinutes),
  };
};

const DelayStatistics = ({ flights, statsView, setStatsView }) => {
  const getDelayData = () => {
    const now = new Date();
    const startDate = statsView === 'weekly' ? startOfWeek(now) : startOfMonth(now);
    const endDate = statsView === 'weekly' ? endOfWeek(now) : endOfMonth(now);

    const relevantFlights = flights.filter(flight => {
      const flightDate = new Date(flight.date);
      return flightDate >= startDate && flightDate <= endDate;
    });

    const delayData = relevantFlights.map(flight => {
      const { delayed, departureDelay, arrivalDelay } = calculateDelay(flight);
      return {
        date: format(new Date(flight.date), 'MM/dd'),
        departureDelay,
        arrivalDelay,
      };
    });

    return delayData;
  };

  return (
    <Card>
      <CardContent>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Delay Statistics</h2>
          <Select value={statsView} onChange={(e) => setStatsView(e.target.value)}>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={getDelayData()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="departureDelay" fill="#8884d8" name="Departure Delay" />
            <Bar dataKey="arrivalDelay" fill="#82ca9d" name="Arrival Delay" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

const PassengerStatistics = ({ flights, statsView, setStatsView }) => {
  const getPassengerData = () => {
    const now = new Date();
    const startDate = statsView === 'weekly' ? startOfWeek(now) : startOfMonth(now);
    const endDate = statsView === 'weekly' ? endOfWeek(now) : endOfMonth(now);

    const relevantFlights = flights.filter(flight => {
      const flightDate = new Date(flight.date);
      return flightDate >= startDate && flightDate <= endDate;
    });

    const passengerData = relevantFlights.reduce((acc, flight) => {
      const dateKey = format(new Date(flight.date), 'MM/dd');
      if (!acc[dateKey]) {
        acc[dateKey] = { date: dateKey, passengers: 0 };
      }
      acc[dateKey].passengers += flight.passengers || 0;
      return acc;
    }, {});

    return Object.values(passengerData);
  };

  return (
    <Card>
      <CardContent>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Passenger Statistics</h2>
          <Select value={statsView} onChange={(e) => setStatsView(e.target.value)}>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={getPassengerData()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="passengers" stroke="#8884d8" name="Passengers" />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default function AdminDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [user, setUser] = useState(null);
  const [currentSection, setCurrentSection] = useState('Dashboard');
  const [currentSubSection, setCurrentSubSection] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [flights, setFlights] = useState([]);
  const [statsView, setStatsView] = useState('weekly');
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [messagesDropdownOpen, setMessagesDropdownOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(3);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [matriculas, setMatriculas] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const profileDropdownRef = useRef(null);
  const messagesDropdownRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentNavItem = navigation.find(item => item.href === currentPath) || 
                           navigation.find(item => item.submenu && item.submenu.some(subItem => subItem.href === currentPath));
    
    if (currentNavItem) {
      setCurrentSection(currentNavItem.name);
      if (currentNavItem.submenu) {
        const currentSubItem = currentNavItem.submenu.find(subItem => subItem.href === currentPath);
        if (currentSubItem) {
          setCurrentSubSection(currentSubItem.name);
        }
      } else {
        setCurrentSubSection(null);
      }
    }
  }, [location]);

  useEffect(() => {
    const fetchFlights = async () => {
      const flightsCollection = collection(db, 'flights');
      const startDate = new Date(2024, 0, 1); // January 1, 2024
      
      const flightsQuery = query(
        flightsCollection,
        where('date', '>=', startDate)
      );

      const unsubscribe = onSnapshot(flightsQuery, (querySnapshot) => {
        const fetchedFlights = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date instanceof Timestamp ? data.date.toDate() : new Date(data.date),
          };
        });
        setFlights(fetchedFlights);
      });

      return () => unsubscribe();
    };

    fetchFlights();
  }, []);

  useEffect(() => {
    const fetchMatriculas = async () => {
      const matriculasCollection = collection(db, 'matriculas');
      
      const unsubscribe = onSnapshot(matriculasCollection, (matriculasSnapshot) => {
        const matriculasList = matriculasSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMatriculas(matriculasList);

        const maintenanceIndex = navigation.findIndex(item => item.name === 'Maintenance');
        if (maintenanceIndex !== -1) {
          navigation[maintenanceIndex].submenu = matriculasList.map(matricula => ({
            name: matricula.matricula,
            href: `/admin/maintenance/${matricula.id}`,
            icon: Plane
          }));
        }
      });

      return () => unsubscribe();
    };

    fetchMatriculas();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
        setProfileDropdownOpen(false);
      }
      if (messagesDropdownRef.current && !messagesDropdownRef.current.contains(event.target)) {
        setMessagesDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const toggleSubmenu = (name) => {
    setOpenSubmenu(openSubmenu === name ? null : name);
  };

  const handleNavigation = (item, subItem = null) => {
    setCurrentSection(item.name);
    setCurrentSubSection(subItem ? subItem.name : null);
    if (item.submenu) toggleSubmenu(item.name);
    if (item.href !== '#') {
      navigate(item.href);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const sidebarVariants = {
    open: { width: '16rem', transition: { duration: 0.3 } },
    closed: { width: '4rem', transition: { duration: 0.3 } }
  };

  const submenuVariants = {
    open: { 
      opacity: 1, 
      height: 'auto',
      transition: { 
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    closed: { 
      opacity: 0, 
      height: 0,
      transition: { 
        duration: 0.3,
        when: "afterChildren",
      }
    }
  };

  const submenuItemVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -10 }
  };

  const pageTransitionVariants = {
    initial: { opacity: 0, x: -20 },
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`flex h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      {/* Sidebar */}
      <motion.div 
        className="flex flex-col transition-all duration-300 ease-in-out relative overflow-hidden"
        initial={sidebarOpen ? "open" : "closed"}
        animate={sidebarOpen ? "open" : "closed"}
        variants={sidebarVariants}
        style={{
          backgroundImage: "url('https://forums.x-plane.org/screenshots/monthly_2019_08/ST4D-1013.JPG.8428ee966b934875c0657e78f8edf217.JPG')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70 backdrop-filter backdrop-blur-sm z-0"></div>
        <div className="relative z-10 flex-1 flex flex-col">
          <div className="flex items-center justify-between h-16 px-4">
            {sidebarOpen ? (
              <img
                className="h-8 w-auto"
                src="/logo.png"
                alt="Level"
              />
            ) : (
              <img
                className="h-8 w-8"
                src="https://media.licdn.com/dms/image/v2/D4D0BAQEXUwI9LzXkPQ/company-logo_200_200/company-logo_200_200/0/1720697010355/flywithlevel_logo?e=2147483647&v=beta&t=OXuhdzWAdxGLUgExCTtvQJ2vs7PQZXMZyM7lC69DMhY"
                alt="Level"
              />
            )}
          </div>
          <nav className="flex-1 overflow-y-auto px-2 py-4 space-y-1">
            {navigation.map((item) => (
              <li key={item.name} className="list-none">
                {item.submenu ? (
                  <div>
                    <button
                      onClick={() => toggleSubmenu(item.name)}
                      className={classNames(
                        item.name === currentSection
                          ? 'bg-blue-600 bg-opacity-75 text-white'
                          : 'text-gray-300 hover:bg-blue-500 hover:bg-opacity-50 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full transition-all duration-200'
                      )}
                    >
                      <item.icon className={`flex-shrink-0 h-6 w-6 ${sidebarOpen ? 'mr-3' : 'mx-auto'}`} aria-hidden="true" />
                      {sidebarOpen && (
                        <>
                          <span className="flex-1">{item.name}</span>
                          <ChevronDown
                            className={`${
                              openSubmenu === item.name ? 'transform rotate-180' : ''
                            } w-5 h-5 transition-transform duration-200`}
                          />
                        </>
                      )}
                    </button>
                    <AnimatePresence>
                      {sidebarOpen && openSubmenu === item.name && (
                        <motion.ul
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={submenuVariants}
                          className="mt-2 space-y-1 overflow-hidden"
                        >
                          {item.submenu.map((subItem) => (
                            <motion.li key={subItem.name} variants={submenuItemVariants}>
                              <Link
                                to={subItem.href}
                                className={classNames(
                                  subItem.name === currentSubSection
                                    ? 'bg-blue-500 bg-opacity-50 text-white'
                                    : 'text-gray-400 hover:bg-blue-400 hover:bg-opacity-25 hover:text-white',
                                  'group flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md transition-colors duration-200'
                                )}
                                onClick={() => handleNavigation(item, subItem)}
                              >
                                {subItem.icon && <subItem.icon className="mr-3 h-5 w-5" />}
                                {subItem.name}
                              </Link>
                            </motion.li>
                          ))}
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    className={classNames(
                      item.name === currentSection
                        ? 'bg-blue-600 bg-opacity-75 text-white'
                        : 'text-gray-300 hover:bg-blue-500 hover:bg-opacity-50 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-all duration-200'
                    )}
                    onClick={() => handleNavigation(item)}
                  >
                    <item.icon className={`flex-shrink-0 h-6 w-6 ${sidebarOpen ? 'mr-3' : 'mx-auto'}`} aria-hidden="true" />
                    {sidebarOpen && <span>{item.name}</span>}
                  </Link>
                )}
              </li>
            ))}
          </nav>
        </div>
        <button
          onClick={toggleSidebar}
          className={`absolute p-2 rounded-full bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition-all duration-300 ease-in-out ${
            sidebarOpen ? 'bottom-4 right-4' : 'bottom-4 left-1/2 transform -translate-x-1/2'
          }`}
        >
          {sidebarOpen ? <ChevronsLeft className="h-6 w-6" /> : <ChevronsRight className="h-6 w-6" />}
        </button>
      </motion.div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Navigation Bar */}
        <header className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-semibold">{currentSection}</h1>
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search..."
                    className={`${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-900'} px-4 py-2 pl-10 pr-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                  />
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                </div>
                <div className="relative" ref={messagesDropdownRef}>
                  <button 
                    className={`p-1 rounded-full ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                    onClick={() => setMessagesDropdownOpen(!messagesDropdownOpen)}
                  >
                    <span className="sr-only">View messages</span>
                    <MessageSquare className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <AnimatePresence>
                    {messagesDropdownOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className={`origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg py-1 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <div className={`px-4 py-2 text-sm font-semibold border-b ${isDarkMode ? 'text-white border-gray-700' : 'text-gray-900 border-gray-200'}`}>Messages</div>
                        <div className="max-h-60 overflow-y-auto">
                          {[
                            { id: 1, sender: 'John Doe', avatar: 'https://randomuser.me/api/portraits/men/1.jpg', message: 'Hey, can you check the flight schedule?', time: '2 min ago' },
                            { id: 2, sender: 'Jane Smith', avatar: 'https://randomuser.me/api/portraits/women/2.jpg', message: 'Meeting at 3 PM in Conference Room A', time: '1 hour ago' },
                            { id: 3, sender: 'Mike Johnson', avatar: 'https://randomuser.me/api/portraits/men/3.jpg', message: 'New safety protocol update', time: '2 hours ago' },
                          ].map((msg) => (
                            <a key={msg.id} href="#" className={`block px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}>
                              <div className="flex items-center">
                                <img src={msg.avatar} alt={msg.sender} className="h-8 w-8 rounded-full mr-3" />
                                <div className="flex-1 min-w-0">
                                  <p className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{msg.sender}</p>
                                  <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{msg.message}</p>
                                </div>
                                <div className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>{msg.time}</div>
                              </div>
                            </a>
                          ))}
                        </div>
                        <a href="#" className={`block px-4 py-2 text-sm text-center font-medium ${isDarkMode ? 'text-blue-400 hover:bg-gray-700' : 'text-blue-600 hover:bg-gray-100'}`}>View all messages</a>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <div className="relative">
                  <button 
                    className={`p-1 rounded-full ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                    onClick={() => setNotificationCount(0)}
                  >
                    <span className="sr-only">View notifications</span>
                    <Bell className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {notificationCount > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                      {notificationCount}
                    </span>
                  )}
                </div>
                <div className="relative" ref={profileDropdownRef}>
                  <button
                    onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
                    className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </button>
                  <AnimatePresence>
                    {profileDropdownOpen && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.2 }}
                        className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <a
                          href="#"
                          className={`flex items-center px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}
                          role="menuitem"
                        >
                          <UserCircle className="mr-3 h-5 w-5" />
                          Your Profile
                        </a>
                        <a
                          href="#"
                          className={`flex items-center px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}
                          role="menuitem"
                        >
                          <SettingsIcon className="mr-3 h-5 w-5" />
                          Settings
                        </a>
                        <a
                          href="#"
                          className={`flex items-center px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}
                          role="menuitem"
                        >
                          <HelpCircle className="mr-3 h-5 w-5" />
                          Help Center
                        </a>
                        <a
                          href="#"
                          onClick={handleSignOut}
                          className={`flex items-center px-4 py-2 text-sm ${isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}
                          role="menuitem"
                        >
                          <LogOut className="mr-3 h-5 w-5" />
                          Sign out
                        </a>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                
                <button
                  className={`p-1 rounded-full ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                >
                  <span className="sr-only">Change language</span>
                  <Globe className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Page Content */}
        <main className={`flex-1 overflow-x-hidden overflow-y-auto ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <AnimatePresence mode="wait">
              <motion.div
                key={location.pathname}
                initial="initial"
                animate="enter"
                exit="exit"
                variants={pageTransitionVariants}
                transition={{ duration: 0.3 }}
              >
                <Routes>
                  <Route path="/" element={
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <DelayStatistics flights={flights} statsView={statsView} setStatsView={setStatsView} />
                      <PassengerStatistics flights={flights} statsView={statsView} setStatsView={setStatsView} />
                    </div>
                  } />
                  <Route path="/passengers" element={<PassengersScreen />} />
                  <Route path="/flights" element={<FlightsScreen />} />
                  <Route path="/manuals" element={<Manuals />} />
                  <Route path="/disruptions" element={<div>Disruptions Management</div>} />
                  <Route path="/maintenance" element={<Maintenance matriculas={matriculas} />} />
                  {matriculas.map(matricula => (
                    <Route 
                      key={matricula.id}
                      path={`/maintenance/${matricula.id}`}
                      element={<Maintenance matricula={matricula} />}
                    />
                  ))}
                  <Route path="/call-center" element={<CallCenter />} />
                  <Route path="/call-center/check-030" element={<Check030 />} />
                  <Route path="/call-center/q-and-a" element={<Qanda />} />
                  <Route path="/crew" element={<div>Crew Management</div>} />
                  <Route path="/roster" element={<RosterSystem />} />
                  <Route path="/contacts" element={<ContactManagement />} />
                  <Route path="/settings/users" element={<UserManagement />} />
                  <Route path="/settings/matriculas" element={<Matriculas />} />
                  <Route path="/settings/vuelos" element={<div>Vuelos Management</div>} />
                  <Route path="/settings/data" element={<DataUploadScreen />} />
                </Routes>
              </motion.div>
            </AnimatePresence>
          </div>
        </main>
      </div>
    </div>
  );
}